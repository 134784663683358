import { Card, CardContent, CardActionArea, Typography, IconButton, Box } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import { DataSource, DataSourceTypeInfo } from '../types/DataSourceTypes';
import ConfirmDialog from '../../../shared/components/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

interface DataSourceTileProps {
    sourceInfo: DataSourceTypeInfo;
    source?: DataSource;  // Only provided for overview tiles
    isMarketplace?: boolean;
    onSelect?: () => void;
    onConfigure?: (source: DataSource) => void;
    onDelete?: (source: DataSource) => void;
}

const DataSourceTile: React.FC<DataSourceTileProps> = ({
    sourceInfo,
    source,
    isMarketplace = false,
    onSelect,
    onConfigure,
    onDelete
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const { t } = useTranslation();

    const IconComponent = sourceInfo.icon;

    const handleClick = () => {
        if (isMarketplace) {
            onSelect?.();
        } else if (source) {
            onConfigure?.(source);
        }
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        setConfirmDeleteOpen(true);
    };

    return (
        <>
            <Card
                sx={{
                    height: 200,
                    position: 'relative',
                    '&:hover': {
                        boxShadow: 6
                    }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <CardActionArea
                    onClick={handleClick}
                    sx={{ height: '100%' }}
                >
                    <CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: '100%'
                            }}
                        >
                            <Box sx={{ fontSize: 64, mb: 2, color: 'primary.main' }}>
                                <IconComponent />
                            </Box>
                            <Typography variant="h6" component="div" align="center">
                                {sourceInfo.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                                {sourceInfo.description}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>

                {/* Action buttons for overview tiles */}
                {!isMarketplace && source && isHovered && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            display: 'flex',
                            gap: 1,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: 1,
                            padding: 0.5
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={handleDelete}
                            color="error"
                        >
                            <Delete fontSize="small" />
                        </IconButton>
                    </Box>
                )}
            </Card>

            {source && (
                <ConfirmDialog
                    open={confirmDeleteOpen}
                    onClose={() => setConfirmDeleteOpen(false)}
                    confirmQuestion={t('chatbot.dataSources.deleteConfirm', { name: source.name })}
                    onConfirm={() => onDelete?.(source)}
                    severity="error"
                />
            )}
        </>
    );
};

export default DataSourceTile; 