import { FaGlobe, FaFileAlt, FaSearch, FaCode } from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import { MdOutlineEventNote } from 'react-icons/md';
import { AiOutlineApi } from 'react-icons/ai';
import { BiMessageSquareDetail } from 'react-icons/bi';

export enum DataSourceType {
    REALTIME_RAG = 'realtime_rag',
    OPARL = 'oparl',
    INTERVIEWS = 'interviews',
    SERVICE_BW = 'service_bw',
    WEB_SEARCH = 'web_search',
    STATIC_DOCUMENTS = 'static_documents',
    CUSTOM_API = 'custom_api',
    KOMMONE_EVENT = 'kommone_event'
}

export interface DataSource {
    id: string;
    type: DataSourceType;
    name: string;
    description: string;
    status: 'active' | 'inactive' | 'error';
    configuration: Record<string, any>;
    lastUpdated?: Date;
}

export interface DataSourceTypeInfo {
    type: DataSourceType;
    name: string;
    description: string;
    icon: React.ComponentType;
    configurationFields: Array<{
        key: string;
        label: string;
        type: 'text' | 'url' | 'select' | 'number' | 'textarea';
        required: boolean;
        options?: string[];
    }>;
}

export const DATA_SOURCE_TYPES: Record<DataSourceType, DataSourceTypeInfo> = {
    [DataSourceType.REALTIME_RAG]: {
        type: DataSourceType.REALTIME_RAG,
        name: 'Realtime RAG',
        description: 'Live website data for real-time RAG processing',
        icon: FaGlobe,
        configurationFields: [
            {
                key: 'url',
                label: 'Website URL',
                type: 'url',
                required: true
            },
            {
                key: 'maxDepth',
                label: 'Maximum Crawl Depth',
                type: 'number',
                required: true
            }
        ]
    },
    [DataSourceType.OPARL]: {
        type: DataSourceType.OPARL,
        name: 'OParl',
        description: 'Open parliament data integration',
        icon: BsBuilding,
        configurationFields: [
            {
                key: 'endpoint',
                label: 'OParl Endpoint',
                type: 'url',
                required: true
            },
            {
                key: 'bodyId',
                label: 'Body ID',
                type: 'text',
                required: true
            },
            {
                key: 'updateInterval',
                label: 'Update Interval (hours)',
                type: 'number',
                required: true
            }
        ]
    },
    [DataSourceType.INTERVIEWS]: {
        type: DataSourceType.INTERVIEWS,
        name: 'CIS Pattern Interviews',
        description: 'Interactive interviews using CIS pattern for gathering and remembering information',
        icon: BiMessageSquareDetail,
        configurationFields: [
            {
                key: 'instructions',
                label: 'Interview Instructions',
                type: 'textarea',
                required: true
            }
        ]
    },
    [DataSourceType.SERVICE_BW]: {
        type: DataSourceType.SERVICE_BW,
        name: 'Service-BW',
        description: 'Baden-Württemberg service data integration',
        icon: AiOutlineApi,
        configurationFields: [
            {
                key: 'municipality',
                label: 'Municipality',
                type: 'text',
                required: true
            },
            {
                key: 'serviceTypes',
                label: 'Service Types',
                type: 'select',
                required: true,
                options: ['All', 'Citizens', 'Business', 'Administration']
            }
        ]
    },
    [DataSourceType.WEB_SEARCH]: {
        type: DataSourceType.WEB_SEARCH,
        name: 'Web Search',
        description: 'Internet search integration',
        icon: FaSearch,
        configurationFields: [
            {
                key: 'searchEngine',
                label: 'Search Engine',
                type: 'select',
                required: true,
                options: ['Google', 'Bing']
            },
            {
                key: 'searchDepth',
                label: 'Search Depth',
                type: 'number',
                required: true
            },
            {
                key: 'domainRestrictions',
                label: 'Domain Restrictions (comma-separated)',
                type: 'text',
                required: false
            }
        ]
    },
    [DataSourceType.STATIC_DOCUMENTS]: {
        type: DataSourceType.STATIC_DOCUMENTS,
        name: 'Static Documents',
        description: 'Upload and manage static document files',
        icon: FaFileAlt,
        configurationFields: []  // No configuration fields as this will use file upload
    },
    [DataSourceType.CUSTOM_API]: {
        type: DataSourceType.CUSTOM_API,
        name: 'Custom API',
        description: 'Connect to a custom API endpoint',
        icon: FaCode,
        configurationFields: [
            {
                key: 'endpoint',
                label: 'API Endpoint',
                type: 'url',
                required: true
            },
            {
                key: 'method',
                label: 'HTTP Method',
                type: 'select',
                required: true,
                options: ['GET', 'POST']
            },
            {
                key: 'headers',
                label: 'Headers (JSON)',
                type: 'text',
                required: false
            },
            {
                key: 'authentication',
                label: 'Authentication Type',
                type: 'select',
                required: true,
                options: ['None', 'API Key', 'Bearer Token', 'Basic Auth']
            },
            {
                key: 'authValue',
                label: 'Authentication Value',
                type: 'text',
                required: false
            }
        ]
    },
    [DataSourceType.KOMMONE_EVENT]: {
        type: DataSourceType.KOMMONE_EVENT,
        name: 'Komm.One Event API',
        description: 'Integration with Komm.One event system',
        icon: MdOutlineEventNote,
        configurationFields: [
            {
                key: 'apiKey',
                label: 'API Key',
                type: 'text',
                required: true
            },
            {
                key: 'eventTypes',
                label: 'Event Types',
                type: 'select',
                required: true,
                options: ['All', 'Public', 'Internal', 'Administrative']
            },
            {
                key: 'municipality',
                label: 'Municipality',
                type: 'text',
                required: true
            }
        ]
    }
}; 