import React, { useState } from 'react';
import { Grid, FormControl, TextField, Tabs, Tab, Box, CircularProgress, Fab } from '@mui/material';
import { BsTranslate } from "react-icons/bs";
import {useChatbotApiClient} from "../../../clients/ChatbotApiClient";

/*
const code2Label:{[key: string]: string} = {
    "de": 'German',
    "en": 'English',
    "tr": 'Türkçe',
    "ua": 'українська',
    "sa": 'العربية',
    "hr": 'Hrvatski',
    "ro": 'Română',
    "al": 'Shqip',
    "rs": 'Српски',
    "hu": 'Magyar',
    "pl": 'Polski',
    "ru": 'Русский',
    "mk": 'Македонски',
    "es": 'Español',
    "fr": 'Français',
    "it": 'Italiano',
    "jp": '日本語',
    "gr": 'Ελληνικά',
    "cn": '中文',
}
 */

const label2Code:{[key: string]: string} = {
    'German': "de",
    'English': "en",
    'Türkçe': "tr",
    'українська': "ua",
    'العربية': "ar",
    'Hrvatski': "hr",
    'Română': "ro",
    'Shqip': "sq",   // Albanisch
    'Српски': "sr",  // Serbisch
    'Magyar': "hu",  // Ungarisch
    'Polski': "pl",
    'Русский': "ru",
    'Македонски': "mk",
    'Español': "es",
    'Français': "fr",
    'Italiano': "it",
    '日本語': "ja",    // Japanisch
    'Ελληνικά': "el",  // Griechisch
    '中文': "cn",
}

// languages: { code: string; label: string; icon: React.ReactNode }[];
const languages = [
    { label: 'German', icon: <Box src={"/img/locale/D-A-CH_Flag.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'English', icon: <Box src={"/img/locale/gb.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Türkçe', icon: <Box src={"/img/locale/tr.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'українська', icon: <Box src={"/img/locale/ua.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'العربية', icon: <Box src={"/img/locale/sa.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Hrvatski', icon: <Box src={"/img/locale/hr.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Română', icon: <Box src={"/img/locale/ro.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Shqip', icon: <Box src={"/img/locale/al.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Српски', icon: <Box src={"/img/locale/rs.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Magyar', icon: <Box src={"/img/locale/hu.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Polski', icon: <Box src={"/img/locale/pl.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Русский', icon: <Box src={"/img/locale/ru.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Македонски', icon: <Box src={"/img/locale/mk.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Español', icon: <Box src={"/img/locale/es.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Français', icon: <Box src={"/img/locale/fr.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Italiano', icon: <Box src={"/img/locale/it.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: '日本語', icon: <Box src={"/img/locale/jp.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: 'Ελληνικά', icon: <Box src={"/img/locale/gr.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
    { label: '中文', icon: <Box src={"/img/locale/cn.svg"} component="img" sx={{ width: 16, height: 16 }} /> },
];


interface LanguageTextFieldProps {
    label: string;
    data: {[key: string]: string | string[]};
    onChange: (lang: string, value: string | string[]) => void;
    rows?: number
}

const LanguageTextField: React.FC<LanguageTextFieldProps> = (props: LanguageTextFieldProps) => {

    const chatbotClient = useChatbotApiClient()
    
    const [selectedLang, setSelectedLang] = useState<string>(languages[0].label);
    const [loading, setLoading] = useState(false);

    const handleTabChange = (_event: React.ChangeEvent<{}>, newLang: string) => {
        setSelectedLang(newLang);
    };

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        let res = event.target.value
        let newVal: string | string[] = Array.isArray(props.data[label2Code[selectedLang]])
            ? res.split("\n")
            : res
        
        props.onChange(label2Code[selectedLang], newVal);
    };

    const handleAutoTranslateClick = () => {
        setLoading(true);
        let inTextToTranslate = props.data[label2Code[selectedLang]]
        const textToTranslate = Array.isArray(props.data[label2Code[selectedLang]])
            ? (inTextToTranslate as string[]).join("\n")
            : (inTextToTranslate as string)
        
        const sourceLang = selectedLang
        
        const targetLangs: string[] = []
        languages.forEach(value => {
            const lang = value.label
            if (lang !== sourceLang) {
                targetLangs.push(lang)
            }
        })
        
        chatbotClient.translate({
            textToTranslate: textToTranslate.replaceAll("\n", "<BBBr/>"),
            sourceLanguage: sourceLang,
            targetLanguages: targetLangs
        })
            .then((translationResult: any) => {
                const t = translationResult.translations
                const trans = JSON.parse(
                    t.replaceAll("<br/>", "")
                    .replaceAll("<BBdqt>", "\"")
                    .replaceAll("<BBsqt>", "'")
                ) // 
                Object.keys(trans).forEach((lang: string) => {
                    const res = Array.isArray(props.data[label2Code[selectedLang]])
                        ? trans[lang].split("<BBBr/>")
                        : trans[lang].replaceAll("<BBBr/>", "\n")
                    props.onChange(label2Code[lang], res);
                })
            })
            .catch(() => { })
            .finally(() => setLoading(false))
        // Simulate an async action
    };

    return (
        <Grid item xs={12} style={{ position: 'relative' }}>
            <FormControl variant="outlined" fullWidth>
                <Tabs
                    value={selectedLang}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    sx={{
                        minHeight: 30,
                        '& .MuiTab-root': {
                            minHeight: 30,
                            fontSize: '0.75rem',
                            padding: '4px 8px',
                            maxWidth: 35,
                            minWidth: 35,
                        },
                    }}
                >
                    {languages.map((lang) => (
                        <Tab
                            key={lang.label}
                            label={lang.icon}
                            value={lang.label}
                            iconPosition="start"
                            sx={{
                                backgroundColor: props.data[label2Code[lang.label]] ? 'rgba(0, 123, 255, 0.1)' : 'inherit',
                            }}
                        />
                    ))}
                </Tabs>
                <Box mt={1} position="relative">
                    <TextField
                        label={`${props.label} (${selectedLang})`}
                        multiline
                        rows={props.rows || 6}
                        variant="outlined"
                        fullWidth
                        value={
                            Array.isArray(props.data[label2Code[selectedLang]])
                                ? (props.data[label2Code[selectedLang]] as string[]).join("\n")
                                : (props.data[label2Code[selectedLang]] as string) ?? ""
                        }
                        onChange={handleTextFieldChange}
                    />
                    {/* FAB Button */}
                    <Fab size="small" color="primary"
                        onClick={handleAutoTranslateClick}
                        sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                        }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : <BsTranslate />}
                    </Fab>
                </Box>
            </FormControl>
        </Grid>
    );
};

export default LanguageTextField;
