import { Grid, Typography } from '@mui/material';
import { DataSource } from '../types/DataSourceTypes';
import DataSourceTile from './DataSourceTile';
import { DATA_SOURCE_TYPES } from '../types/DataSourceTypes';
import { useTranslation } from 'react-i18next';

interface DataSourceGridProps {
    sources: DataSource[];
    onConfigureSource: (source: DataSource) => void;
    onDeleteSource: (source: DataSource) => void;
}

const DataSourceGrid: React.FC<DataSourceGridProps> = ({
    sources,
    onConfigureSource,
    onDeleteSource
}) => {
    const { t } = useTranslation();

    if (sources.length === 0) {
        return (
            <Typography variant="body1" align="center" sx={{ mt: 2, fontStyle: 'italic' }}>
                {t('chatbot.dataSources.noSources')}
            </Typography>
        );
    }

    return (
        <Grid container spacing={3} sx={{ mt: 1 }}>
            {sources.map((source) => (
                <Grid item xs={12} sm={6} md={4} key={source.id}>
                    <DataSourceTile
                        sourceInfo={{
                            ...DATA_SOURCE_TYPES[source.type],
                            name: t(`chatbot.dataSources.types.${source.type}.name`),
                            description: t(`chatbot.dataSources.types.${source.type}.description`)
                        }}
                        source={source}
                        onConfigure={() => onConfigureSource(source)}
                        onDelete={() => onDeleteSource(source)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default DataSourceGrid; 