import { Box, Grid, MenuItem, TextField, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { DataSource, DataSourceType, DATA_SOURCE_TYPES } from '../types/DataSourceTypes';
import { useEffect, useState, useCallback } from 'react';
import { FileEntity } from '../../../model/FileEntity';
import UploadFileModal from './UploadFileModal';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import EditableTextField from '../../../shared/components/EditableTextField';
import IncludedInTrainingIcon from './IncludedInTrainingIcon';
import { useFileApiClient } from '../../../clients/FileApiClient';
import { useTranslation } from 'react-i18next';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';

interface FileRowProps {
    file: FileEntity;
    onDeleted?: () => void;
    onUpdate?: (file: FileEntity) => void;
}

const FileRow: React.FC<FileRowProps> = (props) => {
    const fileClient = useFileApiClient();
    const [deleting, setDeleting] = useState(false);
    const { t }: any = useTranslation();

    const deleteFile = () => {
        setDeleting(true);
        fileClient.deleteFile(props.file.id)
            .then(() => props.onDeleted && props.onDeleted())
            .catch(() => { })
            .finally(() => setDeleting(false));
    };

    return (
        <TableRow key={props.file.id}>
            <TableCell align="center">
                <IncludedInTrainingIcon included={props.file.includedInTraining} />
            </TableCell>
            <TableCell>{props.file.name}</TableCell>
            <TableCell>
                <EditableTextField
                    label={t('chatbot.fileTopic')}
                    value={props.file.topic}
                    onValueChangeSubmit={topic => fileClient.updateFileTopic(props.file.id, topic)
                        .then(file => props.onUpdate && props.onUpdate(file))
                        .catch(() => { })
                    }
                />
            </TableCell>
            <TableCell>{dayjs(props.file.uploaded).format("DD/MM/YYYY HH:mm")}</TableCell>
            <TableCell>
                <LoadingButton loading={deleting} onClick={deleteFile} color="error">
                    <Delete fontSize='small' />
                </LoadingButton>
            </TableCell>
        </TableRow>
    );
};

interface DataSourceConfigFormProps {
    source?: DataSource;
    sourceType: DataSourceType;
    onConfigurationChange: (configuration: Record<string, any>) => void;
    botId: string;
}

const DataSourceConfigForm: React.FC<DataSourceConfigFormProps> = ({
    source,
    sourceType,
    onConfigurationChange,
    botId
}) => {
    const [configuration, setConfiguration] = useState<Record<string, any>>(source?.configuration || {});
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [files, setFiles] = useState<FileEntity[]>([]);
    const { t }: any = useTranslation();
    const chatbotClient = useChatbotApiClient();

    // TODO: Move these API calls to datasource-specific endpoints when available
    // For now, reusing the training files endpoints
    const fetchFiles = useCallback(() => {
        if (sourceType === DataSourceType.STATIC_DOCUMENTS && botId) {
            chatbotClient.getChatbot(botId)
                .then(bot => {
                    if (bot.files) {
                        setFiles(bot.files);
                    }
                })
                .catch(() => { });
        }
    }, [botId, sourceType, chatbotClient]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const handleInputChange = (key: string, value: any) => {
        const newConfig = { ...configuration, [key]: value };
        setConfiguration(newConfig);
        onConfigurationChange(newConfig);
    };

    if (sourceType === DataSourceType.STATIC_DOCUMENTS) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        mb: 2,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        onClick={() => setUploadModalOpen(true)}
                    >
                        Upload Document
                    </LoadingButton>
                </Box>

                <Table sx={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="5%" />
                            <TableCell width="45%">
                                {t('chatbot.document')}
                            </TableCell>
                            <TableCell width="30%">
                                {t('chatbot.topic')}
                            </TableCell>
                            <TableCell width="15%">
                                {t('chatbot.uploadedDate')}
                            </TableCell>
                            <TableCell width="5%" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((file) => (
                            <FileRow
                                key={file.id}
                                file={file}
                                onDeleted={fetchFiles}
                                onUpdate={fetchFiles}
                            />
                        ))}
                        {files.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography variant="body1" align="center">
                                        <i>{t('chatbot.noFilesUploaded')}</i>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <UploadFileModal
                    open={uploadModalOpen}
                    onClose={() => {
                        setUploadModalOpen(false);
                        fetchFiles();
                    }}
                    bot_id={botId}
                    subfolder="docs"
                />
            </Box>
        );
    }

    return (
        <Grid container spacing={2} sx={{ maxWidth: 600, mx: 'auto' }}>
            {DATA_SOURCE_TYPES[sourceType].configurationFields.map(field => (
                <Grid item xs={12} key={field.key}>
                    {field.type === 'select' ? (
                        <TextField
                            select
                            fullWidth
                            label={field.label}
                            value={configuration[field.key] || ''}
                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                            required={field.required}
                        >
                            {field.options?.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : field.type === 'textarea' ? (
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label={field.label}
                            value={configuration[field.key] || ''}
                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                            required={field.required}
                        />
                    ) : (
                        <TextField
                            fullWidth
                            type={field.type}
                            label={field.label}
                            value={configuration[field.key] || ''}
                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                            required={field.required}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default DataSourceConfigForm; 