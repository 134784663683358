import { Box, Typography } from '@mui/material';
import { DataSourceType, DATA_SOURCE_TYPES } from '../types/DataSourceTypes';

interface DataSourceHeaderProps {
    sourceType: DataSourceType;
}

const DataSourceHeader: React.FC<DataSourceHeaderProps> = ({
    sourceType
}) => {
    const sourceInfo = DATA_SOURCE_TYPES[sourceType];
    const IconComponent = sourceInfo.icon;

    return (
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,
            mb: 3,
            width: '100%',
            justifyContent: 'center'
        }}>
            <Box sx={{ 
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                fontSize: 40
            }}>
                <IconComponent />
            </Box>
            <Typography variant="h5">
                {sourceInfo.name}
            </Typography>
        </Box>
    );
};

export default DataSourceHeader; 