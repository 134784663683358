import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button, IconButton, Box } from '@mui/material';
import { DATA_SOURCE_TYPES, DataSourceType } from '../types/DataSourceTypes';
import DataSourceTile from './DataSourceTile';
import { useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import DataSourceConfigForm from './DataSourceConfigForm';
import DataSourceHeader from './DataSourceHeader';
import { useTranslation } from 'react-i18next';

interface DataSourceMarketplaceModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (type: DataSourceType, configuration: Record<string, any>) => void;
    botId: string;
}

const DataSourceMarketplaceModal: React.FC<DataSourceMarketplaceModalProps> = ({
    open,
    onClose,
    onSave,
    botId
}) => {
    const [selectedType, setSelectedType] = useState<DataSourceType | null>(null);
    const [config, setConfig] = useState<Record<string, any>>({});
    const { t } = useTranslation();

    const handleSelectType = (type: DataSourceType) => {
        setSelectedType(type);
        // Initialize with empty values
        const initialValues: Record<string, any> = {};
        DATA_SOURCE_TYPES[type].configurationFields.forEach(field => {
            initialValues[field.key] = '';
        });
        setConfig(initialValues);
    };

    const handleBack = () => {
        setSelectedType(null);
        setConfig({});
    };

    const handleClose = () => {
        setSelectedType(null);
        setConfig({});
        onClose();
    };

    const isValid = () => {
        if (!selectedType) return false;
        return DATA_SOURCE_TYPES[selectedType].configurationFields
            .filter(field => field.required)
            .every(field => config[field.key] && config[field.key].length > 0);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh'  // Make the modal taller
                }
            }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                {selectedType && (
                    <IconButton 
                        edge="start" 
                        onClick={handleBack}
                        sx={{ mr: 2 }}
                    >
                        <ArrowBack />
                    </IconButton>
                )}
                {selectedType 
                    ? t('chatbot.dataSources.marketplace.configure', { name: t(`chatbot.dataSources.types.${selectedType}.name`) })
                    : t('chatbot.dataSources.marketplace.title')
                }
            </DialogTitle>
            <DialogContent>
                {selectedType ? (
                    // Configuration View
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        mt: 3
                    }}>
                        <DataSourceHeader sourceType={selectedType} />
                        <Box sx={{ width: '100%', mt: 3 }}>
                            <DataSourceConfigForm
                                sourceType={selectedType}
                                onConfigurationChange={setConfig}
                                botId={botId}
                            />
                        </Box>
                    </Box>
                ) : (
                    // Marketplace View
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        {Object.values(DATA_SOURCE_TYPES).map((sourceInfo) => (
                            <Grid item xs={12} sm={6} md={4} key={sourceInfo.type}>
                                <DataSourceTile
                                    sourceInfo={{
                                        ...sourceInfo,
                                        name: t(`chatbot.dataSources.types.${sourceInfo.type}.name`),
                                        description: t(`chatbot.dataSources.types.${sourceInfo.type}.description`)
                                    }}
                                    isMarketplace={true}
                                    onSelect={() => handleSelectType(sourceInfo.type)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </DialogContent>
            {selectedType && (
                <DialogActions>
                    <Button onClick={handleClose}>{t('chatbot.dataSources.cancel')}</Button>
                    <Button
                        onClick={() => {
                            onSave(selectedType, config);
                            handleClose();
                        }}
                        variant="contained"
                        disabled={!isValid()}
                    >
                        {t('chatbot.dataSources.save')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DataSourceMarketplaceModal; 