// Recorder.tsx
import React, {useEffect, useState, useRef} from 'react';
import {
    IconButton,
    Menu,
    MenuItem,
    Box,
    ButtonGroup
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MicIcon from '@mui/icons-material/Mic';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RecordRTC, {StereoAudioRecorder} from 'recordrtc';
import {transcribeAudio} from "../../clients/AudioClient";

interface Dictaphone2Props {
    onTranscription: (transcription: string, final: boolean) => void
    endRecording?: boolean
    iconColor: string
    iconColorRecording: string
    startRecordingCallback?: () => void
    stopRecordingCallback?: () => void
}

// Extend the HTMLElement type to include the holdTimeout property
interface HTMLElementWithHoldTimeout extends HTMLElement {
    holdTimeout?: NodeJS.Timeout;
}

const Dictaphone2: React.FC<Dictaphone2Props> = (props: Dictaphone2Props) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
    const [mimeType] = useState<string>('audio/ogg; codecs=opus');
    const [fileExtension] = useState<string>('.ogg');

    const recorderRef = useRef<RecordRTC | null>(null);
    const streamRef = useRef<MediaStream | null>(null);

    // State for managing the dropdown menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        // Fetch available audio input devices on component mount
        const fetchAudioDevices = async () => {
            try {
                // Request microphone access to get device labels
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                streamRef.current = stream;

                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
                // Create a map to store unique devices based on groupId
                const uniqueAudioInputDevices = Array.from(new Map(
                            audioInputDevices.map(device => [device.groupId, device])
                        ).values());

                setAudioDevices(uniqueAudioInputDevices);
                if (audioInputDevices.length > 0) {
                    setSelectedDeviceId(audioInputDevices[0].deviceId);
                }
            } catch (error) {
                console.error('Error accessing audio devices:', error);
            }
        };

        fetchAudioDevices();

        // Cleanup on component unmount
        return () => {
            stopRecording(); // Ensure recording is stopped
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.endRecording) {
            stopRecording();
        }
        // eslint-disable-next-line
    }, [props.endRecording]);

    const startRecording = async () => {
        if (props.startRecordingCallback) {
            props.startRecordingCallback()
        }
        try {
            // Get the selected audio device's stream
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {deviceId: selectedDeviceId},
                video: false,
            });
            streamRef.current = stream;

            const recorder = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/ogg',
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                timeSlice: 3000, // Emit data every 3 seconds
                desiredSampRate: 16000,
                ondataavailable: handleDataAvailable,
            });

            recorderRef.current = recorder;
            recorder.startRecording();
            setIsRecording(true);

            // Start silence detection
            startSilenceDetection(stream);
            
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const startSilenceDetection = (stream: MediaStream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        let silenceThreshold = 0.8; // Default threshold, will be dynamically set after calibration
        const silenceDuration = 1500; // Silence duration in ms to trigger stop
        const calibrationDuration = 2000; // Calibration period in ms
        let silenceStart: number | null = null;

        let calibrationStart = performance.now();
        let amplitudeSum = 0;
        let amplitudeCount = 0;

        const intervalId = setInterval(() => {
            analyser.getByteTimeDomainData(dataArray);
            const amplitude = dataArray.reduce((acc, val) => acc + Math.abs(val - 128), 0) / dataArray.length;
            // console.log("Amplitude:", amplitude, "(Threshold:", silenceThreshold, ")");

            const now = performance.now();
            if (now - calibrationStart < calibrationDuration) {
                // Calibration phase: measure average amplitude
                amplitudeSum += amplitude;
                amplitudeCount++;
            } else if (calibrationStart > 0) {
                // End calibration and calculate dynamic silence threshold
                const averageAmplitude = amplitudeSum / amplitudeCount;
                silenceThreshold = averageAmplitude * 0.5; // Set threshold as a percentage of the average (adjust factor as needed)
                calibrationStart = -1; // End calibration
                console.log("Calibrated silence threshold:", silenceThreshold);
            } else {
                // Silence detection phase with dynamic threshold
                if (amplitude < silenceThreshold) {
                    if (!silenceStart) {
                        silenceStart = now;
                    } else if (now - silenceStart > silenceDuration) {
                        console.log('Silence detected, stopping recording...');
                        doStopRecording();
                        audioContext.close();
                        clearInterval(intervalId); // Clear interval to stop checks
                    }
                } else {
                    silenceStart = null; // Reset silence start if sound is detected
                }
            }
        }, 100); // Check every 100ms

        // Clear the interval when recording stops
        const doStopRecording = () => {
            stopRecording();
            clearInterval(intervalId); // Ensure interval is cleared
        };

        setIsRecording(true);
    };

    // fallback timeout in case silence detection fails.
    useEffect(() => {
        if (isRecording) {
            const timeout = setTimeout(() => {
                console.log('Fallback: Stopping recording after 5 minutes.');
                stopRecording();
            }, 30 * 1000); // Stop after 30 secs max

            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [isRecording]);



    const handleDataAvailable = async (blob: Blob, final: boolean = false) => {
        if (blob && blob.size > 0) {
            try {
                const file = new File([blob], `audio${fileExtension}`, {type: mimeType});
                const transcription = await sendAudioToBackend(file);
                if (transcription) {
                    props.onTranscription(transcription, final);
                }
            } catch (uploadError) {
                console.error('Error uploading audio chunk:', uploadError);
            }
        }
    };

    const stopRecording = () => {
        if (props.stopRecordingCallback) {
            props.stopRecordingCallback()
        }
        if (recorderRef.current && isRecording) {
            recorderRef.current.stopRecording(() => {
                const blob = recorderRef.current?.getBlob();
                if (blob && blob.size > 0) {
                    // Handle the final chunk if needed
                    handleDataAvailable(blob, true);
                }
                recorderRef.current = null;
            });
        }
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
        setIsRecording(false);

        setTimeout(() => setIsRecording(false), 500); // Ensure UI updates
    };

    // isRecording state is used to control UI, but if the state update is delayed or skipped (common in React), 
    // the button click may not work correctly.
    // The toggleRecording function depends on isRecording being accurate at the time of clicking.
    // Use the function form of setState to ensure it's always using the latest value:
    const toggleRecording = () => {
        setIsRecording(prevState => {
            if (prevState) {
                stopRecording();
            } else {
                startRecording();
            }
            return !prevState;
        });
    };

    const sendAudioToBackend = async (file: File): Promise<string | null> => {
        console.log('Sending audio to backend, file size:', file.size);

        try {
            const result = await transcribeAudio(file);
            console.log('Transcription result:', result);
            return result;
        } catch (error) {
            console.error('Error transcribing audio', error);
            return null;
        }
    };

    // Handlers for the dropdown menu
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeviceSelect = (deviceId: string) => {
        setSelectedDeviceId(deviceId);
        handleMenuClose();
    };

    return (
        <Box display="flex" alignItems="center">
            <ButtonGroup variant="contained" disableElevation>
                {/* Recording Icon Button */}
                <IconButton
                    onMouseDown={(e) => {
                        e.preventDefault(); // Prevent default action
                        const target = e.target as HTMLElementWithHoldTimeout;

                        // Start a timer to check for hold behavior
                        const holdTimeout = setTimeout(() => {
                            if (!isRecording) {
                                startRecording();
                            }
                        }, 200); // Adjust the timeout to distinguish between click and hold

                        // Save the timeout to clear it later
                        target.holdTimeout = holdTimeout;
                    }}
                    onMouseUp={(e) => {
                        e.preventDefault(); // Prevent default action
                        const target = e.target as HTMLElementWithHoldTimeout;

                        // If the hold timeout exists, clear it and toggle the recording
                        if (target.holdTimeout) {
                            clearTimeout(target.holdTimeout);
                            target.holdTimeout = undefined;

                            // Handle short click behavior
                            toggleRecording();
                        } else if (isRecording) {
                            // Handle release after a hold
                            stopRecording();
                        }
                    }}
                    onMouseLeave={(e) => {
                        // Ensure recording stops if the mouse is released outside the button
                        const target = e.target as HTMLElementWithHoldTimeout;
                        stopRecording(); // Ensure stop is called

                        // Clear hold timeout if it exists
                        if (target.holdTimeout) {
                            clearTimeout(target.holdTimeout);
                            target.holdTimeout = undefined;
                        }
                    }}
                    aria-label={isRecording ? 'Stop Recording' : 'Start Recording'}
                    sx={{
                        color: isRecording ? props.iconColorRecording : props.iconColor,
                        borderRadius: audioDevices.length > 1 ? '4px 0px 0px 4px' : '4px',
                        paddingRight: audioDevices.length > 1 ? '0px' : '8px',
                    }}
                >
                    {isRecording ? <FiberManualRecordIcon/> : <MicIcon/>}
                </IconButton>

                {/* Dropdown Arrow for Device Selection */}
                {audioDevices.length > 1 && <IconButton
                    size="small"
                    onClick={handleMenuOpen}
                    aria-label="Select Microphone"
                    aria-controls={open ? 'microphone-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{borderTopLeftRadius: 4, borderBottomLeftRadius: 4, paddingRight: '0px'}}
                >
                    <ArrowDropDownIcon/>
                </IconButton>}
            </ButtonGroup>

            {/* Device Selection Menu */}
            {audioDevices.length > 1 && <Menu
                id="microphone-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {audioDevices.map(device => (
                    <MenuItem
                        key={device.deviceId}
                        selected={device.deviceId === selectedDeviceId}
                        onClick={() => handleDeviceSelect(device.deviceId)}
                    >
                        {device.label || `Microphone ${device.deviceId}`}
                    </MenuItem>
                ))}
            </Menu>}
        </Box>
    );
};

export default Dictaphone2;
