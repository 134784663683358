import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FaRegFilePdf } from 'react-icons/fa';

interface UrlButtonProps {
    url: string;
    tooltipHtml: string;
    marginLeft: number;
    bgCol: string;
}

const UrlButton: React.FC<UrlButtonProps> = ({ url, tooltipHtml, marginLeft, bgCol }) => {
    const isValidUrl = useMemo(() => {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    }, [url]);

    const getFaviconUrl = (): string => {
        try {
            const { hostname } = new URL(url);
            return `https://www.google.com/s2/favicons?sz=64&domain=${hostname}`;
        } catch {
            return 'https://via.placeholder.com/24?text=?'; // Fallback icon
        }
    };

    const handleButtonClick = () => {
        if (isValidUrl) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <Tooltip
            title={isValidUrl ? <span dangerouslySetInnerHTML={{ __html: tooltipHtml }} /> : 'Quellen ohne URL'}
            arrow
            placement="top"
        >
            <span>
                {/* Use a span to wrap IconButton so it can handle the `disabled` prop */}
                <IconButton
                    onClick={handleButtonClick}
                    disabled={!isValidUrl}
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '50%',
                        boxShadow: 1,
                        width: 32,
                        height: 32,
                        overflow: 'hidden',
                        marginLeft: `${marginLeft}px`,
                    }}
                >
                    {url.toLowerCase().indexOf(".pdf") > -1 ?
                        <FaRegFilePdf/>
                        :
                        <img
                            src={getFaviconUrl()}
                            alt="Favicon"
                            style={{width: 24, height: 24, borderRadius: '50%'}}
                        />
                    }
                    
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default UrlButton;
