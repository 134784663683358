import * as React from 'react';
import {availableAvatars, Chatbot, ChatbotSettings, ColorExtractionResponse, getAvatarImg} from '../../model/Chatbot';
import { useChatbotApiClient } from '../../clients/ChatbotApiClient';
import { useTranslation } from 'react-i18next';
import {ChangeEvent, useEffect, useState} from 'react';
import {
    Alert, Avatar, Box, CircularProgress, Dialog, DialogActions, DialogContent,
    FormControl, Grid, InputLabel, MenuItem, Select, Slider, Switch, Tab, Tabs, TextField, Typography
} from '@mui/material';
import DialogHeader from '../../shared/components/dialogs/DialogHeader';
import Headline from '../../shared/components/Headline';
import {ErrorResponse} from "../../clients/error/ErrorResponse";
import CopyInputField from '../../shared/components/CopyInputField';
import { getLinkForCurrentHost } from '../../shared/tools/routeTools';
import ConfirmDialog from '../../shared/components/dialogs/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Chat from "../chat/chatbot/Chat";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LanguageTextField from '../chatbot/components/LanguageTextFieldProps';

export interface ISettingsModalProps {
    chatbot: Chatbot
    open?: boolean
    onClose?(): void
    onBotChange?(updatedBot: Chatbot): void
}

const SettingsModal: React.FunctionComponent<ISettingsModalProps> = (props: ISettingsModalProps) => {
    
    const chatbotClient = useChatbotApiClient({ preventNotification: true })

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [pageError, setPageError] = useState<string>()

    const [tabIndex, setTabIndex] = useState(0);
    const [availabilityLoading, setAvailabilityLoading] = useState(false)
    const [trainingStatusLoading, setTrainingStatusLoading] = useState(false)
    const [savingLoading, setSavingLoading] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [deletingBot, setDeletingBot] = useState(false)
    const [webRagSelectorsStr, setWebRagSelectorsStr] = useState<string>(
        props.chatbot.settings.web_rag_selectors ? props.chatbot.settings.web_rag_selectors.join(",") : ""
    )
    const [defaultQuestionsStr] = 
        useState<string>(Object.entries(props.chatbot.settings.defaultQuestions)
            .map(([key, value]) => `${key}|${value}`)
            .join("\n")
        )
    const [trainingStatus, setTrainingStatus] = useState<string>(props.chatbot.trainingStatus + "")
    
    const [data, setData] = useState<ChatbotSettings>(props.chatbot.settings!);

    const [suggestedColors, setSuggestedColors] = useState<string[]>([]);
    const [userSelectedColors, setUserSelectedColors] = useState<string[]>([]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        // if (name === "icon" && value) {
        //     updateSuggestedColors()
        // }
    };
    
    const updateSuggestedColors = () => {
        if (data.icon) {
            chatbotClient.extractColors(data.icon, 200)
                .then((extractedColors: ColorExtractionResponse) => {
                    if (extractedColors.colors) {
                        setSuggestedColors(extractedColors.colors);    
                    }
                })
            ;    
        }
    }

    useEffect(() => {
        updateSuggestedColors()
        // eslint-disable-next-line
    }, [data.icon]);

    useEffect(() => {
        // legacy-support for default question: mapping of german-only default questions to new format with all langs 
        if (data.defaultQuestions && Object.entries(data.defaultQuestions).length > 0
            && data.defaultQuestionsByLang && data.defaultQuestionsByLang["de"].length === 0
        ) {
            const deStrs: string[] = Object.entries(props.chatbot.settings.defaultQuestions)
                .map(([key, value]) => `${key}|${value}`);
            setData((prevData) => {
                const newVal = {
                    ...(prevData.defaultQuestionsByLang || {}),
                    "de": deStrs,
                };
                return {
                    ...prevData,
                    defaultQuestionsByLang: newVal, // ensure re-rendering
                };
            });
        }
        // eslint-disable-next-line
    }, [data.defaultQuestions]);
    
    const handleColorChange = (hex: string, name: string) => {
        setData((prevData) => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [name]: hex,
            },
        }));
    };

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const onClose = () => {
        setPageError(undefined)
        props.onClose && props.onClose()
    }
    
    const saveSettings = () => {
        
        setSavingLoading(true)
        
        const data_copy = JSON.parse(JSON.stringify(data))

        data_copy["web_rag_selectors"] = webRagSelectorsStr.split(",")

        const newDefQuest: {[key: string]: string} = {}
        defaultQuestionsStr.split("\n").forEach((val: string) => {
            const tmp = val.split("|")
            newDefQuest[tmp[0]] = tmp[1]
        })
        data_copy["defaultQuestions"] = newDefQuest
        
        setData(data_copy)
        
        
        chatbotClient.setChatbotSettings(props.chatbot.id, data_copy)
            .then((bot) => props.onBotChange && props.onBotChange(bot))
            .catch((err: ErrorResponse) => {
                debugger;
                setPageError(err.message)
            })
            .finally(() => setSavingLoading(false))
    }

    const changeTrainingStatus = (newStatus: string) => {
        if (!trainingStatusLoading) {
            setTrainingStatus(newStatus)
            setTrainingStatusLoading(true)
            chatbotClient.setChatbotTrainingStatus(props.chatbot.id, newStatus)
                .then((bot) => props.onBotChange && props.onBotChange(bot))
                .catch((err: ErrorResponse) => {
                    debugger;
                    setPageError(err.message)
                })
                .finally(() => setTrainingStatusLoading(false))
        }
    }
    
    const toggleAvailability = () => {
        if (!availabilityLoading) {
            setAvailabilityLoading(true)
            chatbotClient.setChatbotAvailability(props.chatbot.id, !props.chatbot.settings?.publicAvailable)
                .then((bot) => props.onBotChange && props.onBotChange(bot))
                .catch((err: ErrorResponse) => {
                    debugger;
                    setPageError(err.message)
                })
                .finally(() => setAvailabilityLoading(false))
        }
    }
    
    const deleteChatbot = () => {
        if (!deletingBot) {
            setDeletingBot(true)
            chatbotClient.deleteChatbot(props.chatbot.id)
                .then(() => navigate('/chatBots'))
                .catch((err: ErrorResponse) => setPageError(err.message))
                .finally(() => setDeletingBot(false))
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'md'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={t('chatbot.settingsModal.customizeYourBot')}  
                    closeDialog={onClose} 
                />
                <DialogContent>
                    {pageError &&
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        </Grid>
                    }
                    <Box sx={{borderBottom: 1, borderColor: "divider", marginBottom: 2}}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                        >
                            <Tab label="Allgemein"/>
                            <Tab label="Welcome & Wait"/>
                            <Tab label="Design"/>
                            <Tab label="Features"/>
                            <Tab label="Datenschutz"/>
                            <Tab label="Info-Page"/>
                            <Tab label="Erweitert"/>
                        </Tabs>
                    </Box>

                    {/* General Settings */}
                    {tabIndex === 0 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                            
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"General Settings"} size='section' />
                            </Grid>
    
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Training Status-label">{"Training Status"}</InputLabel>
                                    <Select
                                        labelId="Training Status-label"
                                        value={trainingStatus + ""}
                                        onChange={(e) => changeTrainingStatus(e.target.value)}
                                        label={"Training Status"}
                                    >
                                        {["NOT_TRAINED", "PENDING", "TRAINING", "TRAINED", "FAILED"]
                                            .map(e => <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
    
                            <Grid item xs={4}>
                                <TextField fullWidth label="NLP Task" name="nlpTask" 
                                           value={data.nlpTask} onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="nlpModel-label">{"NLP-Model"}</InputLabel>
                                    <Select
                                        labelId="nlpModel-label"
                                        value={data.nlpModel}
                                        onChange={(e) => {
                                            const name = "nlpModel"
                                            setData((prevData) => ({
                                                ...prevData,
                                                [name]: e.target.value,
                                            }));
                                            }
                                        }
                                        label={"NLP-Model"}
                                    >
                                        {[
                                            "OpenAI/gpt-4o",
                                            "Mistral AI/Mistral Large (24.02)",
                                            // "Mistral AI/Mixtral 8x7B Instruct", 
                                            // "Mistral AI/Mistral 7B Instruct",
                                            // "Amazon/Titan Text G1 - Express",
                                            "Antropic/Claude 3.5 Sonnet", "Antropic/Claude 3 Sonnet", "Antropic/Claude 3 Haiku",  
                                            "Amazon/Nova Pro", "Amazon/Nova Lite", "Amazon/Nova Micro",
                                            "Meta/Llama 3.2 1B Instruct", "Meta/Llama 3.2 3B Instruct",
                                        ].map(e => <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Temperature" name="temperature"
                                    value={data.temperature} onChange={handleChange}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField label="Default System Promt (de)" name="defaultSystemPromptDe"
                                        value={data.defaultSystemPromptDe} onChange={handleChange}
                                        multiline rows={6}
                                    />
                                </FormControl>
                            </Grid>
    
                            {/* Web-Rag */}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Realtime-RAG"} size='section' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Always relate your answer to..." name="web_rag_city"
                                           value={data.web_rag_city} onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth label="Base-URL" name="web_rag_base_url"
                                           value={data.web_rag_base_url} onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth label="Element Selector(s)"
                                           value={webRagSelectorsStr} onChange={(e) => setWebRagSelectorsStr(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth label="Factor" name="web_rag_max_search_results"
                                           value={data.web_rag_max_search_results} onChange={handleChange}
                                           type="number"
                                />
                            </Grid>

                            {/* Publication */}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={t('chatbot.settingsModal.publicAvailability')} size='section' />
                            </Grid>

                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                {availabilityLoading ?
                                    <CircularProgress size='25px' sx={{ margin: '0.25em' }} /> :
                                    <Switch onClick={toggleAvailability} checked={props.chatbot.settings?.publicAvailable} />
                                }
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>
                                    <b>Veröffentlichen</b><br/>
                                    {props.chatbot.settings?.publicAvailable ? t('chatbot.settingsModal.publicAvailable') : t('chatbot.settingsModal.notPublicAvailable')}
                                    {props.chatbot.settings?.publicAvailable &&
                                        <CopyInputField
                                            label={t('chatbot.settingsModal.publicLink')} text={getLinkForCurrentHost(`/bot/${props.chatbot.id}/chat`)} />
                                    }
                                </Typography>
                            </Grid>



                            {/* Delete Bot */}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={t('chatbot.settingsModal.deleteYourBot')} size='section' />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton loading={deletingBot} variant="contained" color="error" onClick={() => setConfirmDeleteOpen(true)}>
                                    {t('chatbot.deleteBot')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}

                    {/*Welcome- and wait-message*/}
                    {tabIndex === 1 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                            
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Welcome- and Wait-messages"} size='section' />
                            </Grid>
                            
                            <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                <LanguageTextField
                                    label="RAG - Welcome Messages"
                                    rows={3}
                                    data={data.welcome_rag!}
                                    onChange={(lang: string, value: string | string[]) => {
                                        const finVal = (value as string[])
                                        setData((prevData) => {
                                            const newWelcomeRag = {
                                                ...(prevData.welcome_rag || {}),
                                                [lang]: finVal,
                                            };
                                            return {
                                                ...prevData,
                                                welcome_rag: newWelcomeRag, // ensure re-rendering
                                            };
                                        });
                                    }}
                                />
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasWaitMessages} onChange={handleSwitchChange} name="hasWaitMessages" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Wait-Messages</b><br/>
                                    Aktiviert unterhaltsame Nachrichten während der Chatbot die Anfrage verarbeitet. Die 
                                    angezeigten Nachrichten können unten eingetragen werden. Jede Nachricht in einer neuen 
                                    Zeile, die Nachricht wird zufällig ausgewählt und ändert sich alle 3 Sekunden.
                                </Typography>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <LanguageTextField
                                        label="Wait Messages"
                                        rows={6}
                                        data={data.w8messages_int || {'de': [""]}}
                                        onChange={(lang: string, value: string | string[]) => {
                                            const finVal = (value as string[])
                                            setData((prevData) => {
                                                const newWelcomeRag = {
                                                    ...(prevData.w8messages_int || {}),
                                                    [lang]: finVal,
                                                };
                                                return {
                                                    ...prevData,
                                                    w8messages_int: newWelcomeRag, // ensure re-rendering
                                                };
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
    
                            {/*<Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField label="Welcome Messages" name="welcome_rag"
                                               value={data.welcome_rag && data.welcome_rag["de"] && data.welcome_rag["de"].join('\n')} onChange={handleChange}
                                               multiline rows={6}
                                    />
                                </FormControl>
                            </Grid>*/}
                        </Grid>
                    )}

                    {/* Design */}
                    {tabIndex === 2 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                            
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Design"} size='section' />
                            </Grid>
                            
                            <Grid item xs={6} justifyContent='center' sx={{ paddingRight: '15px' }}>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <TextField label="Icon URL" name="icon"
                                        value={data.icon} onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <TextField label="Title Text" name="titleText"
                                        value={data.titleText} onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
    
                                <Grid container spacing={2} justifyContent={"center"}>
                                    {data.colors && [
                                        "primary", // "Headline: Background",
                                        "primaryText", // "Headline: Text",
                                        "question", // "Question: Background",
                                        "questionText", // "Question: Text",
                                        "bot", // "Answer Background",
                                        "botText", // "Answer text",
                                        // "light", // "Headline + Question text",
                                        "timestamp", // "Timestamp text"
                                        "botbackground",
                                        "contrast",
                                        "scroller",
                                    ].map((colorKey) => (<>
                                        <Grid item xs={2} key={colorKey}>
                                            <input type="color" value={(data.colors as Record<string, string>)[colorKey]}
                                                   onChange={(color) => {
                                                       setUserSelectedColors(prevColors => {
                                                           // Append new color, remove oldest if more than 4
                                                           const updatedColors = [...prevColors, color.target.value].slice(-4);
                                                           return updatedColors;
                                                       });
                                                       handleColorChange(color.target.value, colorKey)
                                                   }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} key={`${colorKey}-typography`}>
                                            <Typography>{(
                                                colorKey === "bot" ? "Answer: Background " 
                                                    : (colorKey === "botText" ? "Answer: Text  " 
                                                    : (colorKey === "light" ? "Text: Headline + Question " 
                                                    : (colorKey === "primaryText" ? "Headline: Text" 
                                                    : (colorKey === "primary" ? "Headline: Background" 
                                                    : (colorKey === "question" ? "Question: Background" 
                                                    : (colorKey === "questionText" ? "Question: Text" 
                                                    : (colorKey === "timestamp" ? "Text: Timestamp " 
                                                    : (colorKey === "botbackground" ? "Bot: Background " 
                                                    : (colorKey === "contrast" ? "Bot: Contrast " 
                                                    : (colorKey === "scroller" ? "Scroller " 
                                                    : colorKey)))))))))))}</Typography>
                                            
                                        </Grid>
                                        {/* Horizontal row of circles */}
                                        <Grid item xs={2} key={`${colorKey}-circles`}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {suggestedColors.concat(userSelectedColors).map((circleColor, idx) => (
                                                    <div
                                                        key={idx}
                                                        onClick={() => handleColorChange(circleColor, colorKey)}
                                                        style={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: "50%",
                                                            backgroundColor: circleColor,
                                                            marginRight: 8,
                                                            cursor: "pointer",
                                                            border: "1px solid #ccc",
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        </Grid>
                                    </>))}
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <Typography gutterBottom>
                                        Bot Opacity (0 to 1): {(data.botOpacity || 1).toFixed(2)}
                                    </Typography>
                                    <Slider
                                        value={data.botOpacity}
                                        onChange={(event: Event, newValue: number | number[]) => {
                                            setData({
                                                ...data,
                                                botOpacity: newValue as number,
                                            });
                                        }}
                                        step={0.05} min={0} max={1} aria-labelledby="slider"
                                    />
                                </Grid>
                                
                            </Grid>
                            {/* Chatbot "preview" */}
                            <Grid item xs={6} justifyContent='center' sx={{height: "700px", overflow: "scroll", backgroundColor: "gray"}}>
                                <Chat 
                                    botId={props.chatbot.id}
                                    botName={props.chatbot.name}
                                    botDescription={props.chatbot.description}
                                    botSettings={data}
                                    nlpTask={"RAG"}
                                    undecorated={true}
                                    questionToAsk={"Hallo, wie geht's?"}
                                />
                            </Grid>
    
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Expert Settings"} size='section' />
                            </Grid>
    
                            {/*<Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField label="Waiting Messages" name="w8messages"
                                               value={data.w8messages && data.w8messages.join('\n')} onChange={handleChange}
                                               multiline rows={6}
                                    />
                                </FormControl>
                            </Grid>*/}
                            
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.addSources} onChange={handleSwitchChange} name="addSources" />
                                <Typography align="center">Additional Sources</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.checkAnswers} onChange={handleSwitchChange} name="checkAnswers" />
                                <Typography align="center">Check Answers</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.hasCloseButtonEnabled} onChange={handleSwitchChange} name="hasCloseButtonEnabled" />
                                <Typography align="center">Bot "Close"-Button enabled</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.mirrorIFrameIcon} onChange={handleSwitchChange} name="mirrorIFrameIcon" />
                                <Typography align="center">Mirror iFrame Icon</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.hideChatIconInIFrame} onChange={handleSwitchChange} name="hideChatIconInIFrame" />
                                <Typography align="center">Hide chat-icon in iFrame</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                                <Switch checked={data.chatInIFrameInitiallyOpen} onChange={handleSwitchChange} name="chatInIFrameInitiallyOpen" />
                                <Typography align="center">Chat in iFrame open by default</Typography>
                            </Grid>
                        </Grid>
                    )}
                    
                    {/* Features */}
                    {tabIndex === 3 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                            
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Vorgegebene Fragen"} size='section' />
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasDefaultQuestionsEnabled} onChange={handleSwitchChange} name="hasDefaultQuestionsEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Vorgegebene Fragen</b><br/>
                                    Aktiviert einige vorgegebene Fragen, die über der Anfragezeile angezeigt werden. So können 
                                    häufig gestellte Fragen mit einem klick abgesetzt werden.
                                </Typography>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>

                                    {/*<TextField
                                        label="Vorgegebene Fragen"
                                        rows={3}
                                        value={defaultQuestionsStr}
                                        fullWidth multiline
                                        onChange={(e) => setDefaultQuestionsStr(e.target.value)}
                                    />*/}

                                    <LanguageTextField
                                        label="Vorgegebene Fragen"
                                        rows={3}
                                        data={data.defaultQuestionsByLang!}
                                        onChange={(lang: string, value: string | string[]) => {
                                            const finVal = (value as string[])
                                            setData((prevData) => {
                                                const newVal = {
                                                    ...(prevData.defaultQuestionsByLang || {}),
                                                    [lang]: finVal,
                                                };
                                                return {
                                                    ...prevData,
                                                    defaultQuestionsByLang: newVal, // ensure re-rendering
                                                };
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            
                            {/* Features */}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Features"} size='section' />
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasMaliciousQueryProtectionEnabled} onChange={handleSwitchChange} 
                                        name="hasMaliciousQueryProtectionEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Schutz vor bösartigen Nutzeranfragen</b><br/>
                                    Überprüft jede Anfrage auf bösartige, schädliche, illegale oder missbräuchliche Nutzung
                                    des Chatsystems. Die Beantwortung verdächtiger Anfragen wird verweigert. 
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.isRHModeActive} onChange={handleSwitchChange}
                                        name="isRHModeActive" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Red-herring mode</b><br/>
                                    Identifizierte Angriffsversuche werden humorvoll beantwortet - bevor sie genauso abgelehnt werden
                                    wie ohne diesen Modus.
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasSpeechInputEnabled} onChange={handleSwitchChange} name="hasSpeechInputEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Spracheingabe</b><br/>
                                    Aktiviert ein "Mikrofon"-Symbol in der Eingabeleiste des Chatbots. Damit können 
                                    Anwendende ihre Chat-Anfrage einsprechen. Die Audio-Aufnahme wird dann in Echtzeit
                                    in Text umgewandelt (OpenAI-Whisper, DSGVO-konform). Die Audio-Aufnahme wird nur für 
                                    die Transkription verwendet und an keiner Stelle dauerhaft gespeichert.
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasSpeechOutputEnabled} onChange={handleSwitchChange} name="hasSpeechOutputEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Sprachausgabe</b><br/>
                                    Aktiviert ein "Abspiel"-Symbol vor jeder Antwort des Chatbos mit der die textuelle 
                                    Antwort in Sprache umgewandelt wird. Die Umwandlung erfolgt DSGVO-konform.
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasHalucinationIndicatorEnabled} onChange={handleSwitchChange} 
                                        name="hasHalucinationIndicatorEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>
                                    <b>Halluzinations-Indikator</b><br/>
                                    Aktiviert bei jeder Antwort eine Bewertung zwischen einem und drei Sternen. Ein Stern
                                    bedeutet, dass die Antwort nur aus dem LLM-Basiswissen stammt. Dabei ist die Gefahr, 
                                    dass das LLM haluziniert, höher, als wenn zusätzlich zum Basiswissen auch Kontext-Wissen
                                    zur Verfügung steht (2 Sterne). Steht außerdem noch Wissen aus dem Internet zur 
                                    Verfügung (3 Sterne) verringert sich die Halluzinations-Gefahr weiter.
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.showAdditionalHallucinationWarning} onChange={handleSwitchChange} 
                                        name="showAdditionalHallucinationWarning" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>
                                    <b>Zusätzlicher (Halluzinations-) Warnhinweis beim Start</b><br/>
                                    Aktiviert einen zusätzlichen Hinweis beim Starten des Chatbots. <br/>
                                    "Ich weiß viel, kann mich aber leider auch irren. Prüfe wichtige Informationen!"
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasFeedbackEnabled} onChange={handleSwitchChange} name="hasFeedbackEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>
                                    <b>Eingebaute Feedback-Funktion</b><br/>
                                    Aktiviert für jede Antwort eine eingebaute Feedback-Funktion. Die Anwendenden können so 
                                    jede Chatbot-Antwort automatisch per E-Mail an das Entwickler-Team schicken (inkl. 
                                    Chatverlauf) und entsprechend kommentieren. So können z.B: (vermeidlich) falsche 
                                    Auskünfte bequem gemeldet werden.<br/>
                                    <CopyInputField
                                        label={"E-Mail Adresse für Feedback"}
                                        text={data.feedbackEmailAddress}
                                        onChange={(e) => {
                                            const name = "feedbackEmailAddress"
                                            setData((prevData) => ({
                                                ...prevData,
                                                [name]: e.target.value
                                            }));
                                        }}
                                        disabled={"false"}
                                    />
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasAnswerByEmailEnabled} onChange={handleSwitchChange} name="hasAnswerByEmailEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography sx={{marginTop: "10px"}}>
                                    <b>Per E-Mail antworten</b><br/>
                                    Wenn aktiviert beantwortet der Chatbot Anfragen auch per E-Mail. Dafür muss der Bot 
                                    "öffenltich verfügbar" sein (siehe Einstellungen ganz unten).<br />                                
                                    Sie erreichen Ihren bot unter <br/>
                                    <CopyInputField
                                        label={"E-Mail Adresse des Bots"}
                                        text={props.chatbot.id + "@mail.int.botbucket.de"} />
                                    
                                    Diese E-Mail Adresse wird in der E-Mail Antwort des Bots als Absender angegeben: <br/>
                                    <CopyInputField
                                        label={"FROM-Adresse der Bot-Antworten"}
                                        text={data.answerByMailFromAddress}
                                        onChange={(e) => {
                                            const name = "answerByMailFromAddress"
                                            setData((prevData) => ({
                                                ...prevData,
                                                [name]: e.target.value
                                            }));
                                        }}
                                        disabled={"false"}
                                    />
                                    An diese BCC-Adresse werden die E-Mail Antworten in Kopie geschickt: <br/>
                                    <CopyInputField
                                        label={"BCC-Adresse für Bot-Antworten per E-Mail"}
                                        text={data.ccAddressAnswerByMail}
                                        onChange={(e) => {
                                            const name = "ccAddressAnswerByMail"
                                            setData((prevData) => ({
                                                ...prevData,
                                                [name]: e.target.value
                                            }));
                                        }}
                                        disabled={"false"}
                                    />
                                    Answer-Templage:<br/>
                                    <Grid item xs={12} sx={{marginTop: "10px", marginBottom: "10px"}}>
                                        <TextField name={"templateAnswerByMail"}
                                            label="E-Mail Answer Template"
                                            rows={5} value={data.templateAnswerByMail} fullWidth multiline
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasChatCharactersEnabled} onChange={handleSwitchChange} name="hasChatCharactersEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>
                                    <b>Chat-Charaktere</b><br/>
                                    Aktiviert mehrere Chat-Charaktere (unter dem Logo des Chatbots). So kann die Tonalität 
                                    der Chatbot-Antworten z.B. auf "Yoda" oder "Nostradamus" geändert werden.
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasDownloadChatAsPDFenabled}
                                        onChange={handleSwitchChange} name="hasDownloadChatAsPDFenabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Chat-Verlauf als PDF herunterladen</b><br/>
                                    Aktiviert im Chatfenster einen Download-Knopf mit dem der Chat-Verlauf (Fragen und
                                    Antworten) als PDF heruntergeladen werden kann.
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasChatWithFilesEnabled}
                                        onChange={handleSwitchChange} name="hasChatWithFilesEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Mit Dateien Chatten</b><br/>
                                    Mit dieser Funktion können Nutzende eigene Dateien als Wissensquelle für den Bot zur
                                    Verfügung stellen. Die Dateien werden auf den Servern nur verarbeitet und nicht 
                                    gespeichert.
                                </Typography>
                            </Grid>
    
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasChatSessionsEnabled}
                                        onChange={handleSwitchChange} name="hasChatSessionsEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Muli-Session-Chat aktivieren</b><br/>
                                    Aktiviert die Chatsession-verwaltung. Die aktuelle Chat-session wird lokal im Browser-
                                    cache gespeichert und kann bei Bedarf wiederhergestellt werden. Außerdem können mehrere
                                    Chat-Sessions parallel verwaltet und zwischen ihnen gewechselt werden.  
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasAutoRestoreLastChatSessionsEnabled}
                                        onChange={handleSwitchChange} name="hasAutoRestoreLastChatSessionsEnabled" />
                            </Grid>
                            <Grid item xs={11}>                            
                                <Typography><b>Letzte Chat-Session automatisch wiederherstellen.</b><br/>
                                    Öffnet die letzte Chat-Session automatisch wenn der Chatbot wieder geöffnet wird. Wenn
                                    deaktiviert wird eine neue (leere) Session gestartet.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {/* --------------- Data Protection --------------------*/}
                    {tabIndex === 4 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                        
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Datenschutz"} size='section' />
                            </Grid>
    
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Link zur Datenschutzerklärung</b><br/>
                                    Die URL unter der weitere Informationen zum Datenschutz zu finden sind. Auf diese wird 
                                    in der "Einverständniserklärung" beim Start des Bots verlinkt.<br/>
                                    <CopyInputField
                                        label={"Link zur Datenschutzerklärung"}
                                        text={data.linkToDataProtectionStatement}
                                        onChange={(e) => {
                                            const name = "linkToDataProtectionStatement"
                                            setData((prevData) => ({
                                                ...prevData,
                                                [name]: e.target.value
                                            }));
                                        }}
                                        disabled={"false"}
                                    />
                                </Typography>
                            </Grid>
                                                    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasProtocolChatQueriesEnabled} onChange={handleSwitchChange} name="hasProtocolChatQueriesEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Chat-Anfragen Protokollieren</b><br/>
                                    Wenn aktiviert werden alle Anfragen des Benutzers und die Antworten des Bots gemeinsam 
                                    mit der Chat-Historie für drei Monate gespeichert. Anschließend werden sie automatisch 
                                    gelöscht. Chat-Anfragen mit personenbezug können von der Protokollierung ausgenommen 
                                    werden (siehe unten).
                                </Typography>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasCheckChatQueriesCheckForPersonalDataEnabled} 
                                        onChange={handleSwitchChange} name="hasCheckChatQueriesCheckForPersonalDataEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Chat-Anfragen auf personenbezug prüfen</b><br/>
                                    Wenn aktiviert wird jede Anfrage des Anwendenden vor der Beantwortung auf 
                                    personenbezogene Daten (Namen) hin untersucht. Falls in der Anfragen personenbezogene 
                                    Daten durch den Anwendenden geteilt wurden kann eine Rückfrage beim Anwendenden erfolgen 
                                    oder die Antwort verweigert werden (siehe Einstellungen unten). Außerdem können solche 
                                    Anfragen von der Protokollierung ausgenommen werden.
                                </Typography>
                                <b>Anfragen mit personenbezug</b><br/>
                                <center>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={data.howToHandleQueriesWithPersonalData}
                                        exclusive
                                        onChange={(_: React.MouseEvent<HTMLElement>, newValue: number)=> {
                                            if (newValue !== null) {
                                                const name = "howToHandleQueriesWithPersonalData"
                                                setData((prevData) => ({
                                                    ...prevData,
                                                    [name]: newValue,
                                                }));
                                            }
                                        }}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value={0}>Immer <br/>beantworten</ToggleButton>
                                        <ToggleButton value={1}>Nachfragen, dann<br/>ggf. beantworten</ToggleButton>
                                        <ToggleButton value={2}>Ablehnen und<br/>nicht beantworten</ToggleButton>
                                    </ToggleButtonGroup>
                                </center>
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasDisableChatQueriesWithPersonalDataExcludedFromProtocolEnabled} 
                                        onChange={handleSwitchChange} name="hasDisableChatQueriesWithPersonalDataExcludedFromProtocolEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Chat-Anfragen mit personenbezug nicht Protokollieren</b><br/>
                                    Wenn aktiviert werden Anfragen des Benutzers mit personenbezug nicht protokolliert. 
                                    Dafür muss die Prüfung der Anfragen auf Personenbezug aktiviert sein. 
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasWarningAboutSharingPersonalDataEnabled} 
                                        onChange={handleSwitchChange} name="hasWarningAboutSharingPersonalDataEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Warn-Hinweis zum nicht-teilen von personenbezogenen Daten</b><br/>
                                    Aktiviert einen Hinweis über der Eingabezeile, die den Nutzenden darauf hinweist,
                                    keine personenzogene Daten zu teilen.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {/* ----------- Info-Page -----------*/}
                    {tabIndex === 5 && (
                        <Grid container spacing={2} justifyContent={"center"}>

                        
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Info-Page"} size='section' />
                            </Grid>
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasInfoPageEnabled} onChange={handleSwitchChange} name="hasInfoPageEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Info-Page</b><br/>
                                    Aktiviert eine Info-Page, die über das info-icon oben rechts eingesehen werden kann
                                </Typography>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <LanguageTextField
                                        label="Info-Page"
                                        rows={6}
                                        data={data.descriptions || {'de': [""]}}
                                        onChange={(lang: string, value: string | string[]) => {
                                            const finVal = (value as string[])
                                            setData((prevData) => {
                                                const newDescriptions = {
                                                    ...(prevData.descriptions || {}),
                                                    [lang]: finVal,
                                                };
                                                return {
                                                    ...prevData,
                                                    descriptions: newDescriptions, // ensure re-rendering
                                                };
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {/* Erweitert */}
                    {tabIndex === 6 && (
                        <Grid container spacing={2} justifyContent={"center"}>
                        
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Avatar"} size='section' />
                            </Grid>
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.avatarEnabled} onChange={handleSwitchChange} name="avatarEnabled" />
                            </Grid>
                            <Grid item xs={11} container>
                                <Grid container spacing={2} justifyContent={"center"}>
                                    <Grid item xs={10} >
                                        <Typography><b>Avatar aktivieren</b><br/>
                                            Aktiviert einen interaktiven Avatar der die Antworten vorträgt.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Avatar
                                            src={getAvatarImg(data.avatarId || "")}
                                            alt={""}
                                            style={{ width: '72px', height: '72px', marginRight: '8px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="Avatar-ID-label">{"Avatar-ID"}</InputLabel>
                                            <Select
                                                labelId="Avatar-ID-label"
                                                value={data.avatarId}
                                                onChange={(e) => {
                                                    const newVal = e.target.value
                                                    setData((prevData) => ({
                                                        ...prevData,
                                                        "avatarId": newVal,
                                                    }));
                                                }}
                                                renderValue={(selected) => {
                                                    const selectedOption = availableAvatars.find((avatar) => avatar.name === selected);
                                                    return (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Avatar
                                                                src={selectedOption?.imageUrl}
                                                                alt={selectedOption?.name}
                                                                style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                            />
                                                            {selectedOption?.name}
                                                        </div>
                                                    );
                                                }}
                                                label={"Avatar-ID"}
                                            >
                                                {availableAvatars.map((avatar) => (
                                                    <MenuItem key={avatar.name} value={avatar.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Avatar
                                                                src={avatar.imageUrl}
                                                                alt={avatar.name}
                                                                style={{ width: '72px', height: '72px', marginRight: '8px' }}
                                                            />
                                                            {avatar.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField label="Custom Avatar-ID" name="avatarIDCustom"
                                                   value={data.avatarIDCustom} onChange={handleChange}
                                                   fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="Avatar Quality">{"Avatar Quality"}</InputLabel>
                                            <Select
                                                labelId="Avatar Quality-label"
                                                value={data.avatarQuality || "Low"}
                                                onChange={(e) => {
                                                    const newVal = e.target.value
                                                    setData((prevData) => ({
                                                        ...prevData,
                                                        "avatarQuality": newVal,
                                                    }));
                                                }}
                                                label={"Avatar Quality"}
                                            >
                                                {["Low", "Medium", "High"]
                                                    .map(e => <MenuItem key={e} value={e}>
                                                        {e}
                                                    </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Avatar Background URL" name="avatarBackgroundUrl"
                                                   value={data.avatarBackgroundUrl} onChange={handleChange}
                                                   fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            {/* CIS */}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"CIS - Conversational Information Seeking"} size='section' />
                            </Grid>
    
                            <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                                <Switch checked={data.hasCISpatternEnabled} onChange={handleSwitchChange} name="hasCISpatternEnabled" />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography><b>Lern-Modus (CIS-Pattern)</b><br/>
                                    Aktiviert den "Lern-Modus" des Chatbots in dem durch die Anwendenden Dokumente
                                    hochgeladen werden können um das Kontext-Wissen des Chatbots zu erweitern. Außerdem kann
                                    sich ein Anwendender in diesem Modus durch den Chatbot zu einem bestimmten Thema
                                    interviewen lassen. Die Anworten werden dann ebenfalls dem Kontextwissen hinzugefügt.
                                </Typography>
                                <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                    <LanguageTextField
                                        label="CIS - Welcome Messages"
                                        rows={3}
                                        data={data.welcome_cis!}
                                        onChange={(lang: string, value: string | string[]) => {
                                            const finVal = (value as string[])
                                            setData((prevData) => {
                                                const newWelcomeRag = {
                                                    ...(prevData.welcome_cis || {}),
                                                    [lang]: finVal,
                                                };
                                                return {
                                                    ...prevData,
                                                    welcome_cis: newWelcomeRag, // ensure re-rendering
                                                };
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>              
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <LoadingButton loading={savingLoading} onClick={() => saveSettings()} variant="contained">
                                {"Save"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                confirmQuestion={t('chatbot.confirmDelete', { botName: props.chatbot.name })}
                confirmPhrase={props.chatbot.name}
                onConfirm={() => deleteChatbot()}
                severity={'error'} />
        </>
    );
}

export default SettingsModal;