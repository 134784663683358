import React from 'react';
import Parser from 'html-react-parser';
import { Box, Typography } from '@mui/material';

interface Props {
    htmlString: string;
}

const HtmlRenderer: React.FC<Props> = ({ htmlString }) => {
    // Split the HTML string by <p> and <ul> tags
    const parts = htmlString.split(/(<\/?p[^>]*>|<\/?ul[^>]*>)/).filter(Boolean);

    return (
        <Box>
            {parts.map((part, index) => (
                <Typography key={index} paragraph className={"bb-common-text"} >
                    {Parser(part)}
                </Typography>
            ))}
        </Box>
    );
};

export default HtmlRenderer;