import React, { useState } from "react";
import {
    Button,
    Grid,
    TextField,
    CircularProgress,
    DialogActions,
    DialogContent, Dialog, Checkbox, FormControlLabel,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import {useChatbotApiClient} from "../../../clients/ChatbotApiClient";
import {FileToVecstoreResponse} from "../../../model/Chatbot";
import DialogHeader from "../../../shared/components/dialogs/DialogHeader";
import CloseIcon from "@mui/icons-material/Close";
import { useFileContext } from "../../../shared/contexts/UserContext";

interface UploadFileModal2rops {
    bot_id: string
    open?: boolean
    onClose?(): void
}

const UploadFileModal2: React.FC<UploadFileModal2rops> = (props: UploadFileModal2rops) => {

    const chatbotClient = useChatbotApiClient({ preventNotification: true })

    const {addFile} = useFileContext();
    const [file, setFile] = useState<File | null>(null);
    
    const [loading, setLoading] = useState(false);
    const [numberExtension, setNumberExtension] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        setLoading(true)

        let fileBlob: Blob = file
        let fileName = file.name
        if (!file.name.toLowerCase().endsWith(".bbz")) {
            try {
                const blobWithHeaders: FileToVecstoreResponse = await chatbotClient.fileToFaiss(file, numberExtension); // Fetch the file as a Blob
                fileBlob = await blobWithHeaders.blob

                // const fileType = blob.type; // MIME type of the file
                // console.log("fileType: " + fileType)
                // Extract the filename from the Content-Disposition header
                const contentDisposition = blobWithHeaders.headers.get("Content-Disposition");
                fileName = contentDisposition
                    ?.split("filename=")[1]
                    ?.replace(/"/g, "") || "downloaded_file.bbz"; // Default to a generic name


            } catch (e) {
                console.error("Error uploading file:", e);
            }
        }

        // Save the file to localStorage
        const arrayBuffer = await fileBlob.arrayBuffer();
        addFile(
            "/faissUsrUploads/" + props.bot_id + "/" + fileName,
            arrayBuffer
        )
        setLoading(false)
        onClose()
    };

    const onClose = () => {
        props.onClose && props.onClose()
    }

    return (
        <Dialog
            open={!!(props.open)}
            PaperProps={{ sx: { backgroundColor: "white" } }}
            disableEnforceFocus
            fullWidth
            maxWidth={'lg'}
            scroll="paper"
            onClose={onClose}
            className={"bb-common-text"}
        >
            <DialogHeader
                headline={"Add File to chat with (5MB max)"}
                closeDialog={onClose} />
            <DialogContent>
            
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <TextField
                            type="file"
                            inputProps={{ accept: ".*" }}
                            onChange={handleFileChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox checked={numberExtension}
                                                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNumberExtension(event.target.checked)} />}
                                          label="Number Extension" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={handleUpload}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Upload"
                    )}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={onClose}
                    disabled={loading}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadFileModal2;
