
const ChatContainerStyles = {
    width: "100vw",
    // height: "75vh",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "12px"
}

const ChatContainerFormContainerStyles = {
    display: "flex",
    justifyContent: "space-between"
}

const ChatContainerFormContainerInputStyles = {
    width: "100%",
    borderRadius: "10px",
    marginRight: "10px",
    backgroundColor: "white"
}

const ChatContainerFormContainerButtonStyles = {
    background: "white",
    color: "#557A46",
    borderStyle: "none",
    borderRadius: "10px",
    padding: "0.5em",
    fontSize: "1em"
}


export {
    ChatContainerStyles,
    ChatContainerFormContainerStyles, 
    ChatContainerFormContainerInputStyles, 
    ChatContainerFormContainerButtonStyles
}


