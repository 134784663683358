import React, {ReactNode, useCallback, useMemo, useState} from "react";
import Chats from "../chats/Chats";
import {useLocation, useNavigate} from 'react-router-dom';
import {ChatContainerStyles} from "./ChatbotStyles";
import {Alert, Avatar, Box, Button, Chip, Grid, IconButton, Link, Tooltip, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {ChatResponse, PageContent, VideoSearchMatch} from "../../../model/ChatResponse";
import i18n from "i18next";
import {ChatbotSettings, Embeddings, FeedbackContent} from "../../../model/Chatbot";
import {useChatbotApiClient} from "../../../clients/ChatbotApiClient";
import {IoCloseSharp, IoSettingsOutline} from "react-icons/io5";
import ChatSettingsModal from "./ChatSettingsModal";
import {useHelperBotContext} from "../../../shared/contexts/HelperBotContext";
import {useTranslation} from "react-i18next";
import {cleanLinks} from "../../../shared/tools/StringTools";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import LanguageSwitcher from "../../../shared/components/LanguageSwitcher";
import {IoIosInformationCircleOutline, IoIosSend} from "react-icons/io";
import {useChatSessionContext, useUserContext} from "../../../shared/contexts/UserContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {PiBird, PiButterfly, PiChartLineUpLight, PiCrown, PiGraduationCapLight, PiMoonStars} from "react-icons/pi";
import {TbMessageChatbot} from "react-icons/tb";
import {BsEmojiLaughing} from "react-icons/bs";
import {GiTiedScroll} from "react-icons/gi";
import {HiOutlineHandThumbUp} from "react-icons/hi2";
import {HiOutlineKey} from "react-icons/hi";
import HtmlRenderer from "../../../shared/components/HTMLRenderer";
import {RiChatDownloadLine, RiChatUploadLine} from "react-icons/ri";
import PulsatingButton from "../../../shared/components/PulsatingButton";
import SpeechBubble from "../../../shared/components/SpeechBubble";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Dictaphone2 from "../../../shared/components/Dictaphone2";
import InteractiveAvatar from "../../chatbot/components/InteractiveAvatar2";
import {AvatarQuality} from "@heygen/streaming-avatar";
import UploadFileModal2 from "../../chatbot/components/UploadFileModal2";
import FileIconsList from "../../chatbot/components/FileIconsList";
import { MdOutlineAttachFile } from "react-icons/md";
import { LuTreeDeciduous } from "react-icons/lu";
import { CiFilter } from "react-icons/ci";
import HierarchyTree from "../../chatbot/components/HierarchyTree";
import UploadFileModal from "../../chatbotSettings/components/UploadFileModal";
import LoginModal from "./LoginModal";
import SessionsIconsList from "../../chatbot/components/SessionIconsList";

interface ChatProps {
    nlpTask: string
    nlpModel?: string | null 
    botName: string
    botDescription: string
    botId: string
    undecorated?: boolean
    questionToAsk?: string
    botTopics?: string[]
    botSettings: ChatbotSettings
    postVideoToIframe?: (videoData: {title: string, url: string, jwt: string, searchMatches: VideoSearchMatch[], thumbnail: string})=>void
    userGaveConsent?: boolean
    onClose?: () => void
    sendEventFromIFrameToParent?: (eventName: string, eventPayload: string) => void
}


export interface ChatMessage {
    purpose: string;
    message: string;
    options?: string[];
    sender: string;
    timestamp: string;
    sources: Array<PageContent>;
    best_sources: string;
    cost: number;
    query_about: string;
}

interface AvatarOption {
    icon: ReactNode;
    name: string;
    descr: string;
    prompt: string;
}

// Wer ist Bürgermeister? Antworte in Bokeltsen Platt
const avatarOptions = [
    {icon: <IoIosSend />, name: 'Standard', descr: 'Formell und höflich', prompt: 'Verhalte dich formell, höflich und empatisch. Nutze eine respektvolle Ansprache und höfliche Formulierungen.'},
    {icon: <PiButterfly />, name: 'Elton John', descr: 'Umgangssprachlich und locker', prompt: 'Verhalte dich wie Elton John, umgangssprachlich und locker. Sprich die Nutzer in einem entspannten, freundlichen Ton an.'},
    {icon: <BsEmojiLaughing />, name: 'Robin Williams', descr: 'Humorvoll und witzig', prompt: 'Verhalte dich wie Robin Williams, humorvoll und witzig. Nutze humorvolle, unterhaltsame Formulierungen, um den Nutzern ein Lächeln zu schenken.'},
    {icon: <PiBird />, name: 'Ulmer Schwabe', descr: 'Schwäbisch und Bodenständig', prompt: 'Sprich im Schwäbischen Dialekt. Nutze typische Ausdrücke und eine freundliche, bodenständige Art.'},
    {icon: <LuTreeDeciduous />, name: 'Meckermann', descr: 'Bokeltsen Platt', prompt: 'Sprich in Bokeltsen Platt. Nutze typische Ausdrücke und eine freundliche, bodenständige Art.'},
    {icon: <PiChartLineUpLight />, name: 'Billie Eilish', descr: 'Jugendlich und trendy', prompt: 'Verhalte dich wie Billie Eilish, jugendlich und trendy. Nutze moderne, coole Ausdrücke und eine lockere Ansprache.'},
    {icon: <PiCrown />, name: 'Johann Wolfgang von Goethe', descr: 'Historisch und altmodisch', prompt: 'Verhalte dich wie Johann Wolfgang von Goethe, historisch und altmodisch. Nutze gehobene Sprache und förmliche Ansprache.'},
    {icon: <GiTiedScroll />, name: 'William Shakespeare', descr: 'Lyrisch und poetisch', prompt: 'Verhalte dich wie William Shakespeare, lyrisch und poetisch. Nutze poetische Formulierungen und eine kreative Ansprache.'},
    {icon: <HiOutlineHandThumbUp />, name: 'Oprah Winfrey', descr: 'Motivierend und inspirierend', prompt: 'Verhalte dich wie Oprah Winfrey, motivierend und inspirierend. Nutze aufmunternde und positive Formulierungen, um die Nutzer zu ermutigen.'},
    {icon: <PiMoonStars />, name: 'Nostradamus', descr: 'Mystisch und geheimnisvoll', prompt: 'Verhalte dich wie Nostradamus, mystisch und geheimnisvoll. Nutze geheimnisvolle und rätselhafte Formulierungen, um eine mystische Atmosphäre zu schaffen.'},
    {icon: <HiOutlineKey />, name: 'Yoda', descr: 'Weise und kryptisch', prompt: 'Verhalte dich wie Yoda aus Star Wars, weise und kryptisch. Nutze eine umgekehrte Satzstruktur und weise, rätselhafte Formulierungen.'},
];

const avatarOptions_bt = [
    {icon: <IoIosSend />, name: 'Standard', descr: 'Formell und höflich', prompt: 'Verhalte dich formell, höflich und empatisch. Nutze eine respektvolle Ansprache und höfliche Formulierungen.'},
    {icon: <LuTreeDeciduous />, name: 'Meckermann', descr: 'Bokeltsen Platt', prompt: 'Formuliere deine Antworten im Deutschen Dialekt Bokeltsen Platt. Nutze typische Ausdrücke und eine freundliche, bodenständige Art.'},
];



// Generate UUID from timestamp and random values
const generateUuid = () => {
    const timestamp = Date.now().toString(16); // Convert timestamp to hexadecimal
    const randomPart = Math.random().toString(16).substring(2, 10); // Random hex string
    return `${timestamp}-${randomPart}`;
};

const getLatestUuid = (uuids: string[], botId: string) => {

    if (!uuids || uuids.length === 0) {
        return null; // Handle the empty array case
    }
    
    return uuids.reduce((latestUuid, currentUuid) => {
        const latestTimestamp = parseInt(latestUuid.replace("/bbSession/" + botId + "/", "").split('-')[0], 16);
        const currentTimestamp = parseInt(currentUuid.replace("/bbSession/" + botId + "/", "").split('-')[0], 16);

        // Compare timestamps and return the UUID with the latest timestamp
        return currentTimestamp > latestTimestamp ? currentUuid : latestUuid;
    });
};


const getAnswerLanguage = (lang: string): string => {
    if (lang === "de") {
        return "German";
    } else if (lang === "en") {
        return "English";
    } else if (lang === "tr") {
        return "Turkish";
    } else if (lang === "ua") {
        return "Ukrainian";
    } else if (lang === "ar") {
        return "Arabic";
    } else if (lang === "hr") { // Kroatisch
        return "Croatian";
    } else if (lang === "ro") { // Rumänisch 
        return "Romanian";
    } else if (lang === "sq") { // Albanisch
        return "Albanian";
    } else if (lang === "sr") { // Serbisch
        return "Serbian";
    } else if (lang === "hu") { // Ungarisch
        return "Hungarian";
    } else if (lang === "pl") { // Polnisch
        return "Polish";
    } else if (lang === "ru") { // Russisch
        return "Russian";
    } else if (lang === "mk") { // Mazedonisch
        return "Macedonian";
    } else if (lang === "es") { // Spanisch
        return "Spanish";
    } else if (lang === "fr") { // Französisch
        return "French";
    } else if (lang === "it") { // Italienisch
        return "Italian";
    } else if (lang === "ja") { // Japanisch
        return "Japanese";
    } else if (lang === "el") { // Griechisch 
        return "Greek";
    } else {
        return "English";
    }
}

const emptyMessage = {
    purpose: "introduction",
    message: "...",
    sender: "bot",
    timestamp: new Date().toLocaleString(),
    sources: new Array<PageContent>(),
    best_sources: "",
    cost: 0,
    query_about: "",
}


const Chat: React.FC<ChatProps> = (props: ChatProps) => {

    const chatbotClient = useChatbotApiClient({ preventNotification: true })
    const userCtx = useUserContext()
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation()
    const {chatSessions, addChatSession} = useChatSessionContext();
    
    // eslint-disable-next-line
    const {questionToAsk, setQuestionToAsk } = useHelperBotContext()
    // const isMobile = useCheckMobileScreen()
    
    const botOpacity = props.botSettings.botOpacity || 1

    const defaultSysPrompt_en = `You are a helpful, respectful and honest assistant. Your name is '{bot.name}'.`
    const defaultSysPrompt_de = `Du bist ein hilfreicher, respektvoller und ehrlicher Assistent. Dein Name ist '{bot.name}'.`
    
    const preparePrompt = (input: string) => {
        return input.replaceAll("{{bot.name}}", props.botName).replaceAll("{{botName}}", props.botName)
    }
    
    // Chat settings
    const [botTitleText, setBotTitleText] = useState<string>(props.botName)
    const [botIcon, setBotIcon] = useState<string>("/img/bb_solo_small.png")
    const [botColors, setBotColors] = useState<{ [key: string]: string }>({
        primary: '#557A46',
        light: '#F2EE9D',
        timestamp: '#858585',
        bot: '#C7C7C7',
        botText: '#000',
        botbackground: '#ffffff',
        contrast: '#000000', 
    })
    const [loginRequired, setLoginRequired] = useState<boolean>(props.botSettings?.hasUserAuthBeforeChatEnabled || false)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [botUsername, setBotUsername] = useState<string>("")
    const [botPassword, setBotPassword] = useState<string>("")


    const [sessionData, setSessionData] = useState<{
        uuid: string;
        chatHistoryFromServer: string;
        messages: ChatMessage[];
    }>({
        uuid: "",
        chatHistoryFromServer: "[]",
        messages: [emptyMessage]
    });

    // const [uuid, setUuid] = useState<string>("");
    // const [chatHistoryFromServer, setChatHistoryFromServer] = useState<string>("[]")
    // const [messages, setMessages] = useState<ChatMessage[]>([emptyMessage])
    
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false)
    const [embeddings, setEmbeddings] = useState<Embeddings>(Embeddings.OPEN_AI_TE_3_LARGE)
    const [nlpModel, setNlpModel] = useState<string>((props.nlpModel || props.botSettings?.nlpModel || "OpenAI/gpt-4o")) // "OpenAI/gpt-3.5-turbo" "OpenAI/gpt-4o"  "meta/llama-2-70b-chat"
    const [nlpTask, setNlpTask] = useState<string>(props.nlpTask) // "RAG" - "CIS"
    const [maxNewTokens, setMaxNewTokens] = useState<number>(props.botSettings?.maxNewTokens || 1500)
    const [temperature, setTemperature] = useState<number>(props.botSettings?.temperature || 0.01)
    const [fetchSimilarDocs, setFetchSimilarDocs] = useState<number>(props.botSettings?.chunksToFetch || 5)
    const [defaultSysPromt, setDefaultSysPromt] = useState<string>(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
    const [textToSay, setTextToSay] = useState<string>("")
    // unused
    const [maxLen, setMaxLen] = useState<number>(1500)
    const [topP, setTopP] = useState<number>(0.9)
    const [similarityScoreThreshold, setSimilarityScoreThreshold] = useState<number>(0.2)
    // const [promt, setPromt] = useState<string>(promt_en)
    
    const [autoReadResults, setAutoReadResults] = useState<boolean>(false)
    const [checkAnswers, setCheckAnswers] = useState<boolean>(props.botSettings?.checkAnswers || false)
    const [addSources, setAddSources] = useState<boolean>(props.botSettings?.addSources || false)
    const [base64_pdf, setBase64_pdf] = useState<string>("")
    const [streamAnswer, setStreamAnswer] = useState<string>("stream: ")
    const [infoScreen, setInfoScreen] = useState<boolean>(false)
    const [filterVisible, setFilterVisible] = useState<boolean>(false)
    const [endRecording, setEndRecording] = useState<boolean>(false)
    const [isQuestionRecording, setIsQuestionRecording] = useState<boolean>(false)

    const [selctedTopic] = useState<string>("")

    const [addFileModalOpen, setAddFileModalOpen] = useState(false)
    const [attachFileModalOpen, setAttachFileModalOpen] = useState(false)
 
    // Text-to-speech:
    const utterThis = useMemo(() => {
        if (typeof window !== "undefined" && "speechSynthesis" in window) {
            const utterance = new SpeechSynthesisUtterance();
            utterance.lang = i18n.language; // Set language inside the memoized function
            return utterance;
        }
        return null;
        // eslint-disable-next-line
    }, [i18n.language]); // Recreate the object when language changes
    const [easyLangEnabled, setEasyLangEnabled] = React.useState<boolean>(false)

    const [showWaitForResponse, setShowWaitForResponse] = useState<boolean>(false);
    const [userResponse, setUserResponse] = useState<string>("");
    const [currentThought, setCurrentThought] = useState<string>("");
    const [questionFromOutside, setQuestionFromOutside] = useState<string>("");
    const [pageError, setPageError] = useState<string>()

    // avatar select ("personality")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAvatar, setSelectedAvatar] = useState<AvatarOption>({ icon: botIcon, name: '', descr: '', prompt: '' });

    // "hallucination" warning bubble
    const [showBubble, setShowBubble] = useState(true);
    // Feedback-message
    const [awaitUserFeedback, setAwaitUserFeedback] = useState(false);

    const [userGaveConsent, setUserGaveConsent] = useState<boolean>(false); // Initially, user must agree to process data
    
    // this file is send along with the chat request to serve as extra-knowledge
    const [selectedAdditionalFileToChatWith, setSelectedAdditionalFileToChatWith] = useState<File | null>(null);

    const handleDeleteConsent = () => {
        setUserGaveConsent(true);
    };

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
    };

    const handleAvatarMenuItemClick = (option: AvatarOption) => {
        if (option.name === "Standard") {
            setTemperature(props.botSettings?.temperature || 0.01)
            setSelectedAvatar({ icon: botIcon, name: '', descr: '', prompt: '' })
        } else {
            setTemperature(0.5)
            setSelectedAvatar(option);
        }
        handleAvatarClose();
    };

    const handleCloseBubble = () => {
        setShowBubble(false);
    };

    React.useEffect(() => {
        const generatedUuid = generateUuid();
        setSessionData((prevState) => ({
            ...prevState,
            uuid: generatedUuid,
            messages: getWelcomeMessages(i18n.language),
        }));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props.userGaveConsent) {
            setUserGaveConsent(props.userGaveConsent)
        }
        // eslint-disable-next-line
    }, [props.userGaveConsent]);

    
    const createNewSession = () => {
        const generatedUuid = generateUuid();

        setSessionData({
            uuid: generatedUuid,
            chatHistoryFromServer: "[]",
            messages: getWelcomeMessages(i18n.language)
        });
    }
    
    
    const speechHandler = useCallback((msg: string, autoReadResults: boolean) => {
        // console.log(autoReadResults)
        // console.log(msg)
        if (!autoReadResults) {
            console.log("Dont auto-read results")
            return
        } else {
            if (utterThis) {
                utterThis.text = msg
                window.speechSynthesis.speak(utterThis)    
            }
        }
    }, [utterThis])
    
    React.useEffect(() => {
        // console.log("Lang is: " + i18n.language)
        setSessionData((prevState) => ({
            ...prevState,
            messages: getWelcomeMessages(i18n.language),
        }));
        if (i18n.language === "de") {
            // setPromt(promt_de)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptDe || defaultSysPrompt_de))
        } else {
            // setPromt(promt_en)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
        }
        // eslint-disable-next-line
    }, [i18n.language, props.botSettings?.defaultSystemPromptDe, props.botSettings?.defaultSystemPromptEn]);
    
    React.useEffect(()=>{
        setNlpTask(props.nlpTask)
    }, [props.nlpTask])
    
    React.useEffect(()=>{
        if (props.botSettings?.nlpModel) {
            setNlpModel(props.botSettings.nlpModel)
        }
    }, [props.botSettings?.nlpModel])
    
    React.useEffect(()=>{
        if (props.nlpModel ) {
            setNlpModel(props.nlpModel )
        }
    }, [props.nlpModel])
    
    React.useEffect(()=>{
        if (props.botSettings?.temperature) {
            setTemperature(props.botSettings.temperature)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.temperature])
    
    React.useEffect(()=>{
        if (props.botSettings?.titleText) {
            setBotTitleText(preparePrompt(props.botSettings.titleText))    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.titleText])
    
    React.useEffect(()=>{
        if (props.botSettings?.icon) {
            setBotIcon(props.botSettings.icon)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.icon])
    
    React.useEffect(()=>{
        if (props.botSettings?.colors) {
            setBotColors(props.botSettings.colors)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.colors])
    
    React.useEffect(()=>{
        if (props.botSettings?.chunksToFetch) {
            setFetchSimilarDocs(props.botSettings.chunksToFetch)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.chunksToFetch])
    
    React.useEffect(()=>{
        if (props.botSettings?.addSources) {
            setAddSources(props.botSettings.addSources)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.addSources])
    
    React.useEffect(()=>{
        if (props.botSettings?.checkAnswers) {
            setCheckAnswers(props.botSettings.checkAnswers)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.checkAnswers])
    
    React.useEffect(()=>{
        if (props.botSettings?.maxNewTokens) {
            setMaxNewTokens(props.botSettings.maxNewTokens)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.maxNewTokens])
    
    React.useEffect(()=>{
        createNewSession()
        // eslint-disable-next-line
    }, [nlpTask])

    React.useEffect(() => {
        if (props.questionToAsk && props.questionToAsk.length > 0) {
            setUserResponse(props.questionToAsk)
            setQuestionFromOutside(props.questionToAsk)
        }
    }, [props.questionToAsk])

    React.useEffect(() => {
        if (questionFromOutside && questionFromOutside.length > 0) {
            handleSubmit(questionFromOutside, (awaitUserFeedback ? "Feedback-3-send" : ""))
        }
        // eslint-disable-next-line
    }, [questionFromOutside]);

    React.useEffect(() => {
        if (sessionData.messages.length === 1) {
            speechHandler(sessionData.messages[0].message, autoReadResults)
        }
    }, [sessionData.messages, speechHandler, autoReadResults])

    React.useEffect(() => {
        console.log("autoReadResults changed to " + autoReadResults)
    }, [autoReadResults])

    const optionClick = (e: React.MouseEvent<HTMLElement>) => {
        let option = e.currentTarget.dataset.id;
        if (option) {
            // setNextStep(option);
        }
    };
    
    const getAlternativeLang = (lang: string): string => {
        // alternative langs (legacy)
        let lang_search = lang.toLowerCase() + "";
        if (lang === "uk") {
            lang_search = "ua"
        } else if (lang === "ja") {
            lang_search = "jp"
        } else if (lang === "el") {
            lang_search = "gr"
        } else if (lang === "ar") {
            lang_search = "sa"
        } else if (lang === "sq") {
            lang_search = "al"
        } else if (lang === "sr") {
            lang_search = "rs"
        }
        return lang_search
    }

    const getWelcomeMessages = (lang: string): ChatMessage[] => {
        let welcomeMsgs = ["Hi, I'm {{botName}}!"]
        if (props.botSettings) {
            
            // alternative langs (legacy)
            let lang_search = getAlternativeLang(lang)
            
            if (nlpTask === "RAG" && props.botSettings.welcome_rag) {
                if (lang_search in props.botSettings.welcome_rag || lang in props.botSettings.welcome_rag) {
                    welcomeMsgs = props.botSettings.welcome_rag[lang_search] || props.botSettings.welcome_rag[lang]
                } 
            } else if (nlpTask === "CIS" && props.botSettings.welcome_cis) {
                if (lang_search in props.botSettings.welcome_cis || lang in props.botSettings.welcome_cis) {
                    welcomeMsgs = props.botSettings.welcome_cis[lang_search] || props.botSettings.welcome_cis[lang]
                }
            }
        }

        const initMsgs: ChatMessage[] = []
        welcomeMsgs.forEach((msg: string) => {
            initMsgs.push({
                purpose: "introduction",
                message: preparePrompt(msg),
                sender: "bot",
                timestamp: new Date().toLocaleString(),
                sources: new Array<PageContent>(),
                best_sources: "",
                cost: 0,
                query_about: "",
            })
        })
        
        return initMsgs;
    }
    
    // event handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserResponse(e.target.value);
    };

    const handleTranscription = (newTranscription: string, final: boolean) => {
        if (final) {
            setUserResponse(newTranscription);
        } else {
            setUserResponse(prev => prev + newTranscription);
        }

        if (newTranscription.trim().toLowerCase().endsWith("abschicken")
            || newTranscription.trim().toLowerCase().endsWith("abschicken.")
            || newTranscription.trim().toLowerCase().endsWith("abschicken!")
        ) {
            setEndRecording(true)
            handleSubmit(newTranscription.replace("abschicken", "")
                .replace("Abschicken", ""), (awaitUserFeedback ? "Feedback-3-send" : ""))
        }
    };
    
    const onStartQuestionRecording = () => {
        setIsQuestionRecording(true)
    }
    const onStopQuestionRecording = () => {
        setIsQuestionRecording(false)
    }

    const addUserKnowledgeToBot = () => {
        chatbotClient.addUserKnowledgeToBot(props.botId)
    }
    
    const navTo = (targetNlpTask: string) : void => {
        // Parse the current query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or update a query parameter, e.g., set `exampleParam` to `newValue`
        searchParams.set('nlpTask', targetNlpTask);

        // Construct the new URL
        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        // Navigate to the new URL
        navigate(newUrl);
    }
    
    const hexToRgb = (hex: string): string => {
        hex = hex.replace(/^#/, '');

        if (hex.length === 3) {
            hex = hex.split('').map((char) => char + char).join('');
        }

        // Überprüft, ob ein Alpha-Wert im Hex-String vorhanden ist (8-stellig)
        if (hex.length === 8) {
            hex = hex.slice(0, 6);
        }

        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `${r}, ${g}, ${b}`;
    }
    
    const handleChatbotLogin = () => {
        // check session storage, if username/password already in place
        const username = sessionStorage.getItem('/' + props.botId + '/username');
        const password = sessionStorage.getItem('/' + props.botId + '/password');

        if (username && password) {
            setBotUsername(username)
            setBotPassword(password)
            setLoggedIn(true)
        }
    }
    
    const restoreSession = (sessionId: string): void => {
        const storedData = localStorage.getItem(sessionId);
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                const restored_chatMessages = parsedData.chatMessages as ChatMessage[]
                const restored_history = parsedData.historyAsJson as string
                const res_uuid = sessionId.replace("/bbSession/" + props.botId + "/", "")

                setSessionData({
                    uuid: res_uuid,
                    chatHistoryFromServer: restored_history,
                    messages: restored_chatMessages
                });
            } catch (error) {
                console.error('Error parsing localStorage data:', error);
            }
        }
    }
    
    React.useEffect(()=>{
        handleChatbotLogin()
        
        // restore last session if configured
        if (props.botSettings.hasAutoRestoreLastChatSessionsEnabled && chatSessions.length > 0) {
            let chatSessionsOfThisBot = chatSessions.filter(sessionKey => sessionKey.startsWith("/bbSession/" + props.botId + "/"));
            chatSessionsOfThisBot = chatSessionsOfThisBot.filter(sessionKey => !sessionKey.endsWith("/icon"));
            if (chatSessionsOfThisBot.length > 0) {
                const lastUUid = getLatestUuid(chatSessionsOfThisBot, props.botId)
                if (lastUUid) {
                    restoreSession(lastUUid)    
                }
            }
        }
        
        // eslint-disable-next-line
    }, [])

    const handleSubmit = (theQuestion: string, extraParam?: string) => {
        setPageError(undefined)
        setStreamAnswer("")

        if (extraParam === "Feedback-3-send") {
            theQuestion = userResponse
        }

        let userConfirmedPersonalDataProcessing = false
        if (extraParam === "agree_sending_personal_data") {
            userConfirmedPersonalDataProcessing = true
        }                

        if (theQuestion.length === 0) {
            return
        }
        
        if (props.sendEventFromIFrameToParent) {
            props.sendEventFromIFrameToParent("newQuestionSubmitted", theQuestion)
        }

        setSessionData((prevState) => ({
            ...prevState, // Keep existing session data
            messages: [
                ...prevState.messages, // Preserve previous messages
                {
                    purpose: "",
                    message: (selctedTopic.length > 0 ? selctedTopic + ": " : "") + theQuestion,
                    sender: "user",
                    timestamp: new Date().toLocaleString(),
                    sources: [] as PageContent[], // Ensure empty array
                    best_sources: "",
                    cost: 0,
                    query_about: ""
                }
            ]
        }));

        if (extraParam === "dont_send_personal_data") {
            setUserResponse("");
            const res = {
                purpose: "res",
                message: preparePrompt(
                    "In Ordnung."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")

            setSessionData((prevState) => ({
                ...prevState, // Keep existing session data
                messages: [
                    ...prevState.messages, // Preserve previous messages
                    {
                        ...res,
                        sender: "bot",
                        timestamp: new Date().toLocaleString()
                    }
                ]
            }));
            setShowWaitForResponse(false)
            return
        }
        
        if (extraParam === "Feedback-1") {
            setUserResponse("");
            const res = {
                purpose: "feedback",
                message: preparePrompt(
                    "Sicherlich. Das Team freut sich über Lob, konstruktive Kritik und Verbessungsvorschlage. " +
                    "Fügen Sie Ihr Feeback nun unten ein und drücken anschließend auf 'absenden'. " +
                    "Anschließend wird die Eingabe und der komplette Chat-Verlauf an das KI-Team geschickt. " +
                    "Wenn Sie das nicht möchten, können Sie das Chat-Fenster jetzt schließen und keine Daten werden gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-2",
            }
            setAwaitUserFeedback(true)
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setSessionData((prevState) => ({
                ...prevState, // Keep existing session data
                messages: [
                    ...prevState.messages, // Preserve previous messages
                    {
                        ...res,
                        sender: "bot",
                        timestamp: new Date().toLocaleString()
                    }
                ]
            }));
            
            setShowWaitForResponse(false)
            
            return
        } else if (extraParam === "Feedback-3-send") {
            setAwaitUserFeedback(false)
            setUserResponse("");
            const res = {
                purpose: "feedback",
                message: preparePrompt(
                    "Vielen Dank, wir haben das Feedback an das Team gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-4",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            
            setSessionData((prevState) => ({
                ...prevState, // Keep existing session data
                messages: [
                    ...prevState.messages, // Preserve previous messages
                    {
                        ...res,
                        sender: "bot",
                        timestamp: new Date().toLocaleString()
                    }
                ]
            }));
            setShowWaitForResponse(false)
            
            const pdfstring = base64_pdf
            // const feedback = messages[messages.length - 2].message
            const feedback = userResponse

            const dateTime = new Date(); // current date and time
            const dateTimeISO = dateTime.toISOString(); // "2024-08-22T13:47:23.000Z"
            
            const feedbackContent: FeedbackContent = {
                createdAt: dateTimeISO,
                feedback: feedback,
                chatPdfAsBase64: pdfstring
            }

            chatbotClient.giveFeedback(props.botId, feedbackContent)
            
            return
        }

        const fillerMessages = [
            "Gerne beantworte ich Ihre Frage, einen Moment bitte.",
            "Einen Moment bitte, ich schau schnell nach.",
            "Ich kümmere mich sofort darum, einen Moment bitte.",
            "Danke für Ihre Geduld, ich sehe sofort nach.",
            "Einen Moment, ich hole die nötigen Informationen für Sie.",
            "Einen Augenblick, ich recherchiere das für Sie.",
            "Ich bin gleich für Sie da, einen kurzen Moment bitte.",
            "Lassen Sie mich kurz nachsehen, einen Moment bitte.",
            "Ich schaue sofort nach, einen kleinen Augenblick bitte.",
            "Ihre Frage wird gleich beantwortet, einen Moment bitte.",
            "Ich bin gleich für Sie zurück mit der Antwort, einen Augenblick bitte."
        ]
        setTextToSay(fillerMessages[Math.floor(Math.random() * fillerMessages.length)])

        setTimeout(() => {
            setShowWaitForResponse(true)
        }, 200);
        
        const chatRequest = {
            question: theQuestion + " " + (easyLangEnabled ? " Antworte in leichter Sprache." : ""), // + (i18n.language === "de" ? "Antworte ausschließlich auf Deutsch." : "Answer in english only."), // "Can I use the ULTRIS camera while being on an airplane?",
            userConfirmedPersonalDataProcessing: userConfirmedPersonalDataProcessing,
            answerLanguage: getAnswerLanguage(i18n.language),
            history: sessionData.chatHistoryFromServer,
            chat_type: nlpTask,
            extra_param: (extraParam ? extraParam : ""),
            personality_prompt: selectedAvatar.prompt,
            username: botUsername,
            password: botPassword,
        }

        chatbotClient.chatWithChatbot(props.botId, chatRequest,
            (chunk: string, event_type: string) => {
                if (event_type === "answer_chunk") {
                    setStreamAnswer(prevStreamAnswer => prevStreamAnswer + chunk)    
                } else if (event_type === "other") {
                    const evntObj = JSON.parse(chunk) as Record<string, unknown>;
                    if ("chain_chunk" in evntObj && typeof evntObj.chain_chunk === "string") {
                        console.log(evntObj.chain_chunk)
                        setCurrentThought(evntObj.chain_chunk);
                    }
                }
            },
            (lastChunkReceived: string) => {
                setCurrentThought("")
                const fullAnswerObj = lastChunkReceived     
                let query_icon = "o";
                let historyCopy = "[]";
                let res = {
                    purpose: "res",
                    message: "",
                    sources: [{
                        page_content: "",
                        metadata: {
                            source: "",
                            page: 0,
                            topic: "",
                            similarity_score: 0,
                            store: "",
                        }
                    }],
                    best_sources: "",
                    cost: 0,
                    query_about: ""
                }
                
                if (lastChunkReceived.includes("Personal data detected. Are you sure you want to proceed?")) {
                    res["message"] = "⚠ Es scheint, als ob die Anfrage personenbezogene Daten enthält.\n" +
                        "Soll ich diese wirklich bearbeiten?"
                    res["query_about"] = "ask_sending_personal_data " + chatRequest.question
                } else if (lastChunkReceived.includes("Personal data detected, I cannot answer that question, sorry.")) {
                    res["message"] = "🚫 Entschuldigung, Anfragen mit personenbezogenen Daten können nicht beantwortet werden."
                    res["query_about"] = ""
                } else if (lastChunkReceived.includes("Access denied")) {
                    res["message"] = "🚫 " + lastChunkReceived
                    res["query_about"] = ""
                    sessionStorage.removeItem('/' + props.botId + '/username');
                    sessionStorage.removeItem('/' + props.botId + '/password');
                    setBotUsername("")
                    setBotPassword("")
                    setLoggedIn(false)
                    setLoginRequired(true);
                    
                } else {
                    const response = JSON.parse(fullAnswerObj) as ChatResponse;
                    setBase64_pdf(response.history_as_pdf)
                    query_icon = response.query_icon
                    historyCopy = response.history
                    if (response.source_documents !== undefined) {
                        response.source_documents.forEach(pageContent => {
                            pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                        })
                    }
                    res = {
                        purpose: "res",
                        message: preparePrompt(response.result),
                        sources: response.source_documents,
                        best_sources: response.best_sources,
                        cost: response.token_usage.costs,
                        query_about: response.query_about,
                    }
                    setTextToSay(preparePrompt(response.result).replace(/<[^>]*>/g, ""))

                    const videoSources = response.source_documents.filter((source) => source.metadata.store === "video")

                    videoSources.forEach(video => {
                        console.log("video")
                        console.log(props.postVideoToIframe)
                        props.postVideoToIframe && props.postVideoToIframe({
                            title: video.metadata.title ?? 'Unknown title',
                            url: video.metadata.link ?? '',
                            jwt: video.metadata.jwt??'',
                            searchMatches: video.metadata.search_matches ?? [],
                            thumbnail: video.metadata.thumbnail ?? ''
                        })
                    });
                    
                }
               
                speechHandler(res.message, autoReadResults)
                setStreamAnswer("")

                setSessionData((prevState) => {

                    const updatedMessages = [
                        ...prevState.messages, // Keep existing messages
                        {
                            ...res,
                            sender: "bot",
                            timestamp: new Date().toLocaleString(),
                        },
                    ];

                    const newChatHistory = historyCopy !== "[]" ? historyCopy : prevState.chatHistoryFromServer;

                    const updatedSessionData = {
                        ...prevState, // Preserve other session data
                        messages: updatedMessages, // Update only messages
                        chatHistoryFromServer: newChatHistory,
                    };

                    // Ensure chat session is updated only if enabled
                    // Call addChatSession with the updated messages inside this callback to avoid race-condition
                    if (props.botSettings.hasChatSessionsEnabled && updatedSessionData.uuid) {
                        addChatSession(
                            `/bbSession/${props.botId}/${updatedSessionData.uuid}`,
                            query_icon,
                            updatedMessages, // Pass updated messages
                            historyCopy
                        );
                    }

                    return updatedSessionData; // Update state with new sessionData
                });
                
                if (props.sendEventFromIFrameToParent) {
                    props.sendEventFromIFrameToParent("newAnswerReady", res["message"])
                }
                
                setShowWaitForResponse(false)
            },
            (selectedAdditionalFileToChatWith ? selectedAdditionalFileToChatWith : undefined)       
        )
        /*
        chatbotClient.chatWithChatbot(props.botId, chatRequest)
            .then((response: ChatResponse) => {
                setChatHistoryFromServer(response.history)
                setBase64_pdf(response.history_as_pdf)
                response.source_documents.forEach(pageContent => {
                    pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                })
                const res = {
                    purpose: "res",
                    message: response.result,
                    sources: response.source_documents,
                    best_sources: response.best_sources,
                }
                speechHandler(res.message, autoReadResults)
                setMessages(messages => [...messages, { ...res, 
                    sender: "bot", 
                    timestamp: new Date().toLocaleString() 
                }])
                

            })
            .catch((e: ErrorResponse) => { setPageError(e.message) })
            .finally(() => { setShowWaitForResponse(false) })
        */
        setUserResponse("");
         

    };
    
    const avatarQualityType = (qual: string) => {
        if (qual === "Low") {
            return AvatarQuality.Low
        } else if (qual === "Medium") {
            return AvatarQuality.Medium
        } else {
            return AvatarQuality.High
        }
    }

    return (
        <>
            <Box className={"bb-chat-container"} sx={(props.undecorated ? 
                    ({
                        ...ChatContainerStyles,
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                        // marginLeft: "8px",
                        // marginRight: "3px",
                    }) : 
                    ({
                        ...ChatContainerStyles,
                        border: `0.5px solid ${botColors.primary}`,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                        height: "min(90vh, 750px)",
                        width: "min(100vw, 900px)",
                    })
                )} 
                 onKeyDownCapture={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                    }
                }}
            >
                {/*Header bar */}
                <Grid className={"bb-chat-headline-container"} container justifyContent={"space-between"} alignItems={"center"} 
                    sx={{ 
                        backgroundColor: botColors.primary,
                        border: `0.5px solid ${botColors.primary}`,
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        padding: "8px",
                        color: (botColors.primaryText || botColors.light)
                    }}
                >
                    <Grid item>
                        <Grid container alignItems={"center"} spacing={3}>
                            <Grid item>
                                <IconButton onClick={handleAvatarClick}>
                                    <Avatar alt={"bot"} src={botIcon} />                                    
                                </IconButton>
                                {props.botSettings && props.botSettings.hasChatCharactersEnabled && 
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleAvatarClose}
                                    >
                                        {(props.botId === "365cf985-2259-466b-87a2-dcebfd7f87b3" ? avatarOptions_bt : avatarOptions)
                                            .map((option) => (
                                            <MenuItem key={option.prompt} onClick={() => handleAvatarMenuItemClick(option)}>
                                                <Avatar style={{ marginRight: 8 }}>
                                                    {option.icon}
                                                </Avatar>
                                                <ListItemText
                                                    primary={option.name}
                                                    secondary={option.descr}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                }
                            </Grid>
                            <Grid item className={"bb-headline-text"}>
                                {botTitleText}
                            </Grid>
                        </Grid>
                    </Grid>
                    { props.botSettings && props.botSettings.hasInfoPageEnabled && 
                        <Grid item>
                            <PulsatingButton color="inherit" onClick={() => setInfoScreen(!infoScreen)} 
                                             sx={{width: "24px", height: "24px", padding: 0, minWidth: 0, marginRight: "5px"}}>
                                {infoScreen ? 
                                    <IoCloseSharp size={24} /> :
                                    <IoIosInformationCircleOutline size={24} />
                                }
                            </PulsatingButton>
                        { props.botSettings && props.onClose && !infoScreen && 
                            <Button color="inherit" onClick={props.onClose} sx={{width: "24px", height: "24px", padding: 0, minWidth: 0}}>
                                <IoCloseSharp size={24} />
                            </Button>
                        }
                        </Grid>
                    }
                </Grid>
                
                <ChatSettingsModal
                    open={settingsModalOpen}
                    onClose={() => {
                        setSettingsModalOpen(false)
                    }}
                    embeddings={embeddings}
                    setEmbeddings={setEmbeddings}
                    nlpModel={nlpModel} setNlpModel={setNlpModel}
                    nlpTask={nlpTask} setNlpTask={setNlpTask}
                    maxLen={maxLen} setMaxLen={setMaxLen}
                    maxNewTokens={maxNewTokens} setMaxNewTokens={setMaxNewTokens}
                    temperature={temperature} setTemperature={setTemperature}
                    topP={topP} setTopP={setTopP}
                    defaultSysPromt={defaultSysPromt} setDefaultSysPromt={setDefaultSysPromt}
                    fetchSimilarDocs={fetchSimilarDocs} setFetchSimilarDocs={setFetchSimilarDocs}
                    similarityScoreThreshold={similarityScoreThreshold} setSimilarityScoreThreshold={setSimilarityScoreThreshold}
                    speechUtterance={utterThis}
                    autoReadResults={autoReadResults} setAutoReadResults={setAutoReadResults}
                    checkAnswers={checkAnswers} setCheckAnswers={setCheckAnswers}
                    addSources={addSources} setAddSources={setAddSources}
                />
                {pageError &&
                    <Grid container justifyContent='center'>
                        <Alert severity="error" className={"bb-common-text"}>
                            {pageError}
                        </Alert>
                    </Grid>
                }

                {loginRequired && !loggedIn &&
                    <Box
                        sx={{
                            position: "relative",
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                            overflow: "hidden", padding: 0, height: "100%", // 2/3 height
                        }}
                    >
                        <LoginModal botId={props.botId} isOpen={!loggedIn} onClose={handleChatbotLogin} mainColor={props.botId}/>
                    </Box>
                }
                
                {infoScreen &&
                    <Box
                        sx={{
                            overflowY: 'auto',
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")"
                        }}
                    >
                        <Grid container direction="column" sx={{
                            width: '100%', 
                            height: "100%", 
                            padding: "1.5em",
                            justifyContent: 'space-between',
                        }}>
                            <Grid item xs={11}>
                                <Typography fontSize={"10px"} className={"bb-common-text"}>
                                    {"Dieser Chatbot wurde erstellt durch "}
                                    <Link href="https://youniquehorns.com/" variant="inherit" target="_blank">
                                        YOUniquehorns GmbH
                                    </Link>
                                </Typography><br/>
                                <Typography>
                                    <HtmlRenderer htmlString={(
                                        props.botSettings && props.botSettings.descriptions ? props.botSettings.descriptions['de'][0]
                                            .replaceAll("{{botName}}", props.botName) 
                                            .replaceAll("{{linkToDataProtectionDeclaration}}", props.botSettings.linkToDataProtectionStatement) 
                                            .replaceAll("{{feedbackEmail}}", props.botSettings.feedbackEmailAddress) 
                                            : ""
                                    )} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {!infoScreen && 
                    <>
                        {(props.botSettings?.avatarEnabled) ?
                            <>
                                {/* InteractiveAvatar2 takes 2/3 of the space */}
                                <Box
                                    sx={{
                                    position: "relative", 
                                    backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                                    overflow: "hidden", padding: 0, height: "66.66%", // 2/3 height
                                }}
                                >
                                    {/* Fade-out effect overlay */}
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            bottom: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to bottom, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 0) 0%, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 30px, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to top, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 0) 0%, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 30px, rgba(` + hexToRgb(botColors.botbackground || "#ffffff") + `, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    <InteractiveAvatar
                                        userInteracted={userGaveConsent}
                                        botId={props.botId}
                                        avatarName={props.botSettings!.avatarId || ""}
                                        avatarIdCustom={props.botSettings!.avatarIDCustom || undefined}
                                        language={i18n.language}
                                        textToSay={textToSay}
                                        quality={avatarQualityType(props.botSettings!.avatarQuality || "Low")}
                                        buttonColor={(botColors.contrast || botColors.primary)}
                                        backgroundImageUrl={props.botSettings.avatarBackgroundUrl || undefined}
                                        interruptSpeaking={isQuestionRecording}
                                    />
                                </Box>
                                
                                <Box
                                    sx={{
                                        overflowY: "hidden",
                                        backgroundColor: (botColors.botbackground || "#ffffff"),
                                        padding: ".5em",
                                        height: "33.33%", // 1/3 height
                                        position: 'relative', // Position relative to enable absolute positioning of overlay
                                    }}
                                    className={"bb-common-text"}
                                >
                                    {/* Fade-out effect overlay */}
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0, left: 0, right: 0,
                                            height: "40px", // Adjust height as needed for fade effect
                                            background: `linear-gradient(to top, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 0) 0%, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 1) 30px, rgba(${hexToRgb(botColors.botbackground || "#ffffff")}, 1) 100%)`,
                                            pointerEvents: "none", // Prevent the overlay from blocking scroll events
                                            zIndex: 5,
                                        }}
                                    />
                                    
                                    <Chats
                                        showWaitForResponse={showWaitForResponse}
                                        optionClick={optionClick}
                                        messages={sessionData.messages}
                                        numberOfWelcomeMsgs={getWelcomeMessages(i18n.language).length}
                                        streamAnswer={streamAnswer}
                                        undecorated={props.undecorated}
                                        speechUtterance={utterThis}
                                        botId={props.botId}
                                        nlpTask={nlpTask}
                                        chatWithBot={handleSubmit}
                                        botColors={botColors}
                                        botSettings={props.botSettings!}
                                        opacity={undefined}
                                        currentThought={currentThought}
                                        key={"afewsg124"}
                                    />
                                    
                                </Box>
                            </> :
                            <Box
                                sx={{
                                    position: "relative", 
                                    backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")",
                                    overflow: "hidden", padding: 0, height: "100%", // 2/3 height
                                }}
                                className={"bb-common-text"}
                            >
                                <Chats
                                    showWaitForResponse={showWaitForResponse}
                                    optionClick={optionClick}
                                    messages={sessionData.messages}
                                    numberOfWelcomeMsgs={getWelcomeMessages(i18n.language).length}
                                    streamAnswer={streamAnswer}
                                    undecorated={props.undecorated}
                                    speechUtterance={utterThis}
                                    botId={props.botId}
                                    nlpTask={nlpTask}
                                    chatWithBot={handleSubmit}
                                    botColors={botColors}
                                    botSettings={props.botSettings!}
                                    opacity={undefined}
                                    currentThought={currentThought}
                                    key={"af3125qgw24"}
                                />

                                {/* List of files the user attached to this bot */}
                                <Box style={{ position: "absolute", top: 70, right: -32, zIndex: 3 }}>
                                    <FileIconsList 
                                        bot_id={props.botId} 
                                        onEntryEnabledChange={(enabledEntries: string[]) => {
                                            if (enabledEntries.length > 0) {
                                                const storedData  = localStorage.getItem(enabledEntries[0]);
                                                if (storedData) {
                                                    const fileName = enabledEntries[0].replaceAll("/faissUsrUploads/" + props.botId + "/", "")
                                                    const fileType = "application/octet-stream"
                                                    // Parse the stored JSON string back to a Uint8Array
                                                    const byteArray = new Uint8Array(JSON.parse(storedData));
                                                    const blob = new Blob([byteArray], { type: fileType });
                                                    // Recreate the File object
                                                    const restoredFile = new File([blob], fileName, { type: fileType });
                                                    setSelectedAdditionalFileToChatWith(restoredFile);
                                                } else {
                                                    console.error("No file found in localStorage");
                                                }
                                            }
                                        }}
                                        contrastColor={botColors.contrast}
                                    />
                                </Box>
                                
                            </Box>
                        }
                </>}

                {filterVisible && false &&
                    <Grid container direction="column" sx={{
                        width: '100%',
                        height: "100%",
                        background: 'white',
                        padding: "1.5em",
                        justifyContent: 'space-between',
                        backgroundColor: "rgba(" + (botColors.botbackground || "#ffffff") +", " + botOpacity + ")",
                        overflowY: 'auto',
                    }}>
                        <Grid item xs={11}>
                            <Typography fontSize={"10px"}>
                                {"Filter "}
                                <Link href="https://youniquehorns.com/" variant="inherit" target="_blank">
                                    YOUniquehorns GmbH
                                </Link>
                            </Typography><br/>
                            <Typography>
                                <HierarchyTree checkboxColor={botColors.contrast}/>
                            </Typography>
                        </Grid>
                    </Grid>
                }

                <Box sx={{backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", 0)"}}>
                                        
                    {/* Default Questions */}
                    {userGaveConsent && props.botSettings.hasDefaultQuestionsEnabled && props.botSettings.defaultQuestionsByLang &&
                        props.botSettings.defaultQuestionsByLang[(i18n.language === "uk" ? "ua" : i18n.language)] &&
                        Object.entries(props.botSettings.defaultQuestionsByLang[(i18n.language === "uk" ? "ua" : i18n.language)]).length > 0 &&
                        <ListItem sx={{  paddingRight: "0px", color: botColors.primary, fontSize: '12px', 
                            backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")" }}
                                  className={"bb-info-defaultquestions"}
                        >
                            {props.botSettings.defaultQuestionsByLang[(i18n.language === "uk" ? "ua" : i18n.language)]
                                .map((q, i) => {
                                    const parts = q.split("|");
                                    const key = parts[0]
                                    const value = parts[1]
                                    return (<Tooltip title={value} arrow className={"bb-info-defaultquestions"}>
                                        <Chip label={key} size="small"
                                              className={"bb-info-defaultquestions"}
                                              sx={{margin: "0 3px 0 3px", backgroundColor: botColors.botbackground, 
                                                  color: botColors.contrast, borderColor: botColors.contrast
                                                }} variant={"outlined"}
                                              onClick={()=>handleSubmit(value)}
                                        />
                                    </Tooltip>)
                            })}
                        </ListItem>
                    }
                    
                    {/* Don't share personal data info */}
                    {props.botSettings.hasWarningAboutSharingPersonalDataEnabled && 
                        <Box
                            sx={{
                                fontSize: "8px", textAlign: "center", color: "gray", paddingTop: "0px",
                                marginLeft: "auto", marginRight: "auto", display: "flex",
                                justifyContent: "center", alignItems: "center", border: "none", boxShadow: "none", 
                                backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")"
                            }}
                            className={"bb-info-dontsharepersdata"}
                        >
                            {t("chat.dontSharePersonalData")}
                        </Box>
                    }
                                        
                    {/* Input & Settings */}
                    <ListItem alignItems="flex-start" sx={{ padding: "0px" }}>
                        <Paper
                            component="form"
                            sx={{ 
                                p: '8px 8px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                width: "100%", 
                                borderBottomLeftRadius: "10px",
                                backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")", 
                                borderBottomRightRadius: "10px", 
                                borderTopRightRadius: "0px", 
                                borderTopLeftRadius: "0px", 
                                borderTop: `1px solid ${(botColors.contrast || botColors.primary)}`,
                                maxHeight: '200px', overflow: 'auto',
                            }}
                            className={"bb-chat-footer-container"}
                        >
                            {!userGaveConsent ? (
                                <Chip
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', 
                                            color: (botColors.contrast || botColors.primary)}}
                                             className={"bb-common-text"}
                                        >
                                            <LanguageSwitcher
                                                easyLangEnabled={easyLangEnabled}
                                                setEasyLangEnabled={setEasyLangEnabled}
                                            />
                                            <span
                                                onClick={() => {
                                                    if (props.botSettings.linkToDataProtectionStatement.length > 0) {
                                                        window.open(props.botSettings.linkToDataProtectionStatement, '_blank');
                                                    } else {
                                                        setInfoScreen(!infoScreen)
                                                    }
                                                }} 
                                                style={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                    color: '#3f51b5', // Optional: specify the color directly
                                                }}
                                                className={"bb-common-text"}
                                            >
                                                Datenverarbeitung
                                            </span>&nbsp;{' ok?'}
                                            <IconButton size="small" onClick={handleDeleteConsent} sx={{color: (botColors.contrast || botColors.primary)}}>
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => {}} sx={{color: (botColors.contrast || botColors.primary)}}>
                                                <CloseIcon />
                                            </IconButton>

                                        </Box>
                                    }
                                    sx={{ ml: 1 }}
                                />
                            ) : (!awaitUserFeedback 
                                && sessionData.messages.length > 22 
                                && (props.botId === 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' || props.botId === 'fade0059-bae3-4d58-82b8-4aaa4621a644') ?
                                (
                                    <Chip
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }} className={"bb-common-text"}>
                                                Zu viele Anfragen, bitte neu starten.
                                            </Box>
                                        }
                                        sx={{ ml: 1 }}
                                    />
                                )
                                : (
                                    <Grid container sx={{width: "100%"}}>
                                        <Grid item xs={12} sx={{maxHeight: '200px', overflow: 'auto',}}>
                                            {/* User Input */}
                                            <InputBase
                                                className={"bb-common-text"}
                                                sx={{ ml: 1, flex: 1, color: (botColors.contrast || botColors.primary), width: "95%" }}
                                                placeholder={t("chat.userInput")}
                                                inputProps={{ 'aria-label': 'user input' }}
                                                multiline
                                                minRows={1} // Specify the minimum number of rows
                                                onChange={e => handleInputChange(e)}
                                                value={userResponse}
                                            />
                                        </Grid>

                                        {/* Toolbar left*/}
                                        <Grid item xs={3} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center', // Optional: Align items vertically in the center
                                            }}
                                              className={"bb-common-text"}
                                        >
                                            <LanguageSwitcher
                                                easyLangEnabled={easyLangEnabled}
                                                setEasyLangEnabled={setEasyLangEnabled}
                                            />
                                            {/*<Dictaphone1 />*/}
                                            {props.botSettings && props.botSettings.hasSpeechInputEnabled &&
                                                <Dictaphone2
                                                    onTranscription={handleTranscription}
                                                    endRecording={endRecording}
                                                    iconColor={(botColors.contrast || botColors.primary)}
                                                    iconColorRecording={botColors.botText}
                                                    startRecordingCallback={onStartQuestionRecording}
                                                    stopRecordingCallback={onStopQuestionRecording}
                                                />
                                            }
                                            {/* Filter Button */}
                                            { false &&
                                                <ListItem sx={{  paddingRight: "0px", color: botColors.primary, fontSize: '12px',
                                                    backgroundColor: "rgba(" + hexToRgb(botColors.botbackground || "#ffffff") + ", " + botOpacity + ")" }}
                                                >
                                                    <Button color="inherit" onClick={() => setFilterVisible(!filterVisible)} sx={{width: "24px", height: "24px", padding: 0, minWidth: 0, marginRight: "5px"}}>
                                                        <CiFilter size={24} />
                                                    </Button>
                                                </ListItem>
                                            }
                                        </Grid>

                                        {/* Toolbar center */}
                                        <Grid item xs={6} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center', // Optional: Align items vertically in the center
                                                justifyContent: 'center' // Optional: Align the elements to the right
                                            }}
                                              className={"bb-common-text"}
                                        >
                                            {/* Sessions */}
                                            {props.botSettings.hasChatSessionsEnabled &&
                                                <SessionsIconsList
                                                    bot_id={props.botId}
                                                    active_session={sessionData.uuid}
                                                    onEntryEnabledChange={restoreSession}
                                                    contrastColor={botColors.contrast}
                                                    createNewSession={createNewSession}
                                                />
                                            }
                                            
                                            {!props.botSettings.hasChatSessionsEnabled && 
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center", // Center items horizontally
                                                        alignItems: "center", // Center items vertically
                                                        gap: 1,
                                                    }}
                                                >
                                                    <Box key={"create_new_session_12324"}>
                                                        <Tooltip title={"New session"} placement="top" arrow>
                                                            <IconButton
                                                                onClick={() => {
                                                                    // setEnabledEntry("")
                                                                    createNewSession()
                                                                }}
                                                                sx={{
                                                                    border: '0px solid ' + botColors.contrast,
                                                                    backgroundColor: "white.300",
                                                                    color: "white",
                                                                    display: "flex", 
                                                                    alignItems: "center", 
                                                                    justifyContent: "center", 
                                                                    padding: 0,
                                                                    transition: "transform 0.2s",
                                                                    "&:hover": {
                                                                        transform: "scale(1.05)",
                                                                    },
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: "18px",
                                                                        color: botColors.contrast
                                                                    }}
                                                                >
                                                                    {"＋"}
                                                                </span>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            }
                                        </Grid>
                                            
                                        {/* Toolbar right */}
                                        <Grid item xs={3} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center', // Optional: Align items vertically in the center
                                                justifyContent: 'flex-end' // Optional: Align the elements to the right
                                            }}
                                              className={"bb-common-text"}
                                        >
                                            {/* Send Button */}
                                            <IconButton sx={{color: (botColors.contrast || botColors.primary)}} onClick={
                                                () => handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                                            }>
                                                { selectedAvatar.name !== "" ? selectedAvatar.icon : <IoIosSend />}
                                            </IconButton>


                                            {/* Halucination warning */}
                                            {props.botSettings.showAdditionalHallucinationWarning
                                                && showBubble
                                                && userGaveConsent &&
                                                <Box sx={{ position: 'relative', display: 'inline-block', marginTop: '20px' }}>
                                                    <SpeechBubble
                                                        text="Ich weiß viel, kann mich aber leider auch irren. <br/>Prüfe wichtige Informationen!"
                                                        onClose={handleCloseBubble}
                                                        position="bottom-right" // Change position as needed
                                                    />
                                                </Box>
                                            }

                                            {/* Settings button */}
                                            {!props.undecorated && false &&
                                                <>
                                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                                    {(userCtx.isLoggedIn()) &&
                                                        <IconButton onClick={() => setSettingsModalOpen(true)}>
                                                            <IoSettingsOutline />
                                                        </IconButton>
                                                    }
                                                </>
                                            }

                                            {/* switch between nlp-tasks */}
                                            {props.botId !== 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b'
                                                && props.botId !== 'fade0059-bae3-4d58-82b8-4aaa4621a644'
                                                && props.botId !== '8350924f-c329-4573-95d3-de6bc6ca843a'
                                                && props.botSettings.hasCISpatternEnabled
                                                && nlpTask.startsWith('RAG') &&
                                                <>
                                                    <Tooltip title={"Teach the bot something!"}>
                                                        <IconButton onClick={() => navTo("CIS")}>
                                                            <PiGraduationCapLight />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }
                                            {props.botSettings && props.botSettings.hasCISpatternEnabled
                                                && nlpTask.startsWith('CIS') &&
                                                <>
                                                    <Tooltip title={"Upload something"}>
                                                        <IconButton onClick={() => setAddFileModalOpen(true)}>
                                                            <RiChatUploadLine />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Ask the bot something!"}>
                                                        <IconButton onClick={() => navTo("RAG")}>
                                                            <TbMessageChatbot  />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }

                                            {/*Chat with file*/}
                                            {props.botSettings && userGaveConsent
                                                && props.botSettings!.hasChatWithFilesEnabled
                                                && nlpTask.startsWith('RAG') &&
                                                <>
                                                    <Tooltip title={"Attach file to chat with"}>
                                                        <IconButton onClick={() => setAttachFileModalOpen(true)}>
                                                            <MdOutlineAttachFile />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }

                                            {/* pdf download */}
                                            {base64_pdf && base64_pdf.length > 0
                                                && props.botSettings!.hasDownloadChatAsPDFenabled &&
                                                <>
                                                    <a download={"chat_" + new Date().toISOString() + ".pdf"}
                                                       href={"data:application/pdf;base64," + base64_pdf}
                                                    >
                                                        <IconButton >
                                                            <RiChatDownloadLine />
                                                        </IconButton>
                                                    </a>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            )}                            
                        </Paper>
                    </ListItem>

                    {/* Upload dialog for "Teach me something!" */}
                    <UploadFileModal
                        open={addFileModalOpen}
                        subfolder={'userInput'}
                        onClose={() => {
                            setAddFileModalOpen(false)
                            addUserKnowledgeToBot()
                        }}
                        bot_id={props.botId}
                    />
                    <UploadFileModal2 
                        open={attachFileModalOpen}
                        onClose={() => {
                            setAttachFileModalOpen(false)
                        }}
                        bot_id={props.botId}
                    />
                    
                </Box>
            </Box>
        </>
    );
};

export default Chat;
