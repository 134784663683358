import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { DataSource, DATA_SOURCE_TYPES } from '../types/DataSourceTypes';
import DataSourceConfigForm from './DataSourceConfigForm';
import DataSourceHeader from './DataSourceHeader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataSourceSettingsModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (configuration: Record<string, any>) => void;
    source: DataSource;
    botId: string;
}

const DataSourceSettingsModal: React.FC<DataSourceSettingsModalProps> = ({
    open,
    onClose,
    onSave,
    source,
    botId
}) => {
    const [config, setConfig] = useState<Record<string, any>>(source.configuration);
    const { t } = useTranslation();

    const isValid = () => {
        const sourceInfo = DATA_SOURCE_TYPES[source.type];
        return sourceInfo.configurationFields
            .filter(field => field.required)
            .every(field => config[field.key] && config[field.key].length > 0);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh'
                }
            }}
        >
            <DialogTitle>{t('chatbot.dataSources.configureSource')}</DialogTitle>
            <DialogContent>
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    mt: 3
                }}>
                    <DataSourceHeader sourceType={source.type} />
                    <Box sx={{ width: '100%', mt: 3 }}>
                        <DataSourceConfigForm
                            source={source}
                            sourceType={source.type}
                            onConfigurationChange={setConfig}
                            botId={botId}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('chatbot.dataSources.cancel')}</Button>
                <Button
                    onClick={() => {
                        onSave(config);
                        onClose();
                    }}
                    variant="contained"
                    disabled={!isValid()}
                >
                    {t('chatbot.dataSources.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DataSourceSettingsModal; 