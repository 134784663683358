import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Stack, Paper } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import HistoryIcon from "@mui/icons-material/History";

type AnimatedTextSwitcherProps = {
    text: string;
};

export const AnimatedTextSwitcher: React.FC<AnimatedTextSwitcherProps> = ({ text }) => {
    const [history, setHistory] = useState<string[]>([]);
    const [showHistory, setShowHistory] = useState(false);
    const [animKey, setAnimKey] = useState(0);

    useEffect(() => {
        setHistory((prev) => {
            if (prev.length === 0 || prev[prev.length - 1] !== text) {
                return [...prev, text];
            }
            return prev;
        });
        setAnimKey((prev) => prev + 1);
    }, [text]);

    const currentText = history[history.length - 1];

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "visible",
            }}
        >
            {/* History List */}
            <AnimatePresence>
                {showHistory && (
                    <motion.div
                        key="history"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            marginBottom: 8,
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                p: 1,
                                borderRadius: 1,
                                backgroundColor: "#f9f9f9",
                                maxHeight: 150,
                                overflowY: "auto",
                            }}
                        >
                            <Stack spacing={0.5}>
                                {history.map((item, idx) => (
                                    <Typography
                                        key={idx}
                                        sx={{
                                            fontSize: "10px",
                                            color: idx === history.length - 1 ? "black" : "gray",
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Stack>
                        </Paper>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Current Text + Toggle Icon */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <AnimatePresence mode="wait">
                    <motion.div
                        key={`text-${animKey}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.4 }}
                        style={{ position: "relative" }}
                    >
                        <Typography sx={{ fontSize: "10px" }}>{currentText}</Typography>
                    </motion.div>
                </AnimatePresence>

                <IconButton
                    size="small"
                    onClick={() => setShowHistory((prev) => !prev)}
                    sx={{ ml: 1 }}
                >
                    <HistoryIcon fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
};
