import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

interface QRCodeLinkProps {
    url: string;
    title?: string;
    showUrl?: boolean;
    showTitle?: boolean;
}

const QRCodeLink: React.FC<QRCodeLinkProps> = ({ url, title = 'Scan this QR code', showUrl = false, showTitle = true }) => {
    return (
        <Card sx={{ maxWidth: 300, margin: 'auto' }}>
            <CardContent>
                {showTitle && <Typography variant="h6" align="center" gutterBottom>
                    {title}
                </Typography>}
                <Box display="flex" justifyContent="center" alignItems="center">
                    <QRCodeSVG value={url} size={200} />
                </Box>
                { showUrl && <Typography variant="body2" align="center" mt={2}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {url}
                    </a>
                </Typography>}
            </CardContent>
        </Card>
    );
};

export default QRCodeLink;
