import {
    Box,
    Button, Fab, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow,
    Tooltip, Typography
} from '@mui/material';
import * as React from 'react';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import Headline from '../../shared/components/Headline';
import { useCallback, useEffect, useState } from 'react';
import {
    Chatbot,
    TrainingStatus,
    botIsTraining,
    ChatbotContextResponse,
    FileInfo,
    VectorstoreInfo
} from '../../model/Chatbot';
import { PiUsersBold } from "react-icons/pi";
import { useChatbotApiClient } from '../../clients/ChatbotApiClient';
import GlobalLoader from '../../shared/components/GlobalLoader';
import { Add, Delete, Settings } from '@mui/icons-material';
import UploadFileModal from './components/UploadFileModal';
import { FileEntity } from '../../model/FileEntity';
import { useFileApiClient } from '../../clients/FileApiClient';
import { LoadingButton } from '@mui/lab';
import TrainChatbotModal from './components/TrainChatbotModal';
import TrainingStatusIcon from './components/TrainingStatusIcon';
import DisabledToolTip from '../../shared/components/DisabledToolTip';
import { useTranslation } from 'react-i18next';
import SettingsModal from './SettingsModal';
import dayjs from 'dayjs';
import EditableTextField from '../../shared/components/EditableTextField';
import IncludedInTrainingIcon from './components/IncludedInTrainingIcon';
import AddWebsiteModal from './components/AddWebsiteModal';
import {BsFilePdf, BsFileText, BsFileImage, BsFileEarmark } from "react-icons/bs";
import {cleanLinks} from "../../shared/tools/StringTools";
import UserSettingsModal from './UserSettingsModal';
import DataSourceMarketplaceModal from './components/DataSourceMarketplaceModal';
import DataSourceGrid from './components/DataSourceGrid';
import { DataSource, DataSourceType, DATA_SOURCE_TYPES } from './types/DataSourceTypes';
import DataSourceSettingsModal from './components/DataSourceSettingsModal';
import QRCodeLink from '../chatbot/components/QRCodeLink';

// import {useTheme} from "@mui/material/styles";

export interface FileRowProps {
    file: FileEntity
    onDeleted?(): void
    onUpdate?(file: FileEntity): void
}

const FileRow: React.FunctionComponent<FileRowProps> = (props: FileRowProps) => {
    const fileClient = useFileApiClient()
    const [deleting, setDeleting] = useState(false)
    const { t }: any = useTranslation();

    const deleteFile = () => {
        setDeleting(true)
        fileClient.deleteFile(props.file.id)
            .then(() => props.onDeleted && props.onDeleted())
            .catch(() => { })
            .finally(() => setDeleting(false))

    }
    return <TableRow key={props.file.id}>
        <TableCell align="center">
            <IncludedInTrainingIcon included={props.file.includedInTraining} />
        </TableCell>
        <TableCell>
            {(props.file.name.startsWith("http") ?
                <Link href={props.file.name} target="_blank">{props.file.name}</Link> : <>{props.file.name}</>
            )}
        </TableCell>
        <TableCell>
            <EditableTextField
                label={t('chatbot.fileTopic')}
                value={props.file.topic}
                onValueChangeSubmit={topic => fileClient.updateFileTopic(props.file.id, topic)
                    .then(file => props.onUpdate && props.onUpdate(file))
                    .catch(() => { })
                }
            />
        </TableCell>
        <TableCell>{dayjs(props.file.uploaded).format("DD/MM/YYYY HH:mm")}</TableCell>
        <TableCell>
            <LoadingButton loading={deleting} onClick={deleteFile}
                color="error">
                <Delete fontSize='small' />
            </LoadingButton>
        </TableCell>
    </TableRow>
}

export interface IMyChatBotProps {
}

const MyChatBot: React.FunctionComponent<IMyChatBotProps> = (props: IMyChatBotProps) => {
    const { botId } = useParams()
    // const theme = useTheme();
    // const userCtx = useUserContext()
    const navigate = useNavigate()
    const { t }: any = useTranslation();
    const chatbotClient = useChatbotApiClient()

    const [htmlPageCount, setHtmlPageCount] = useState(0)
    const [pdfPageCount, setPdfPageCount] = useState(0)
    const [imgsCount, setImgsCount] = useState(0)
    const [otherContentCount, setOtherContentCount] = useState(0)
    
    const [bot, setBot] = useState<Chatbot>()
    const [addFileModalOpen, setAddFileModalOpen] = useState(false)
    const [addWebsiteModalOpen, setAddWebsiteModalOpen] = useState(false)
    const [trainBotModalOpen, setTrainBotModalOpen] = useState(false)
    const [settingsOpen, setsettingsOpen] = useState(false)
    const [userSettingsOpen, setUserSettingsOpen] = useState(false)
    const fetchBotInterval = React.useRef<NodeJS.Timeout | null>(null)
    
    const [cisInterviews, setCisInterviews] = React.useState<FileInfo[]>([])
    const [cisInfos, setCisInfos] = React.useState<VectorstoreInfo | null>(null)
    const [ragInfos, setRagInfos] = React.useState<VectorstoreInfo | null>(null)
    const [rtragInfos, setRtRagInfos] = React.useState<VectorstoreInfo | null>(null)
    const [eventInfos, setEventInfos] = React.useState<VectorstoreInfo | null>(null)
    const [userInputInfos, setUserInputInfos] = React.useState<VectorstoreInfo | null>(null)
    const [userUploads, setUserUploads] = React.useState<FileInfo[]>([])
    const [marketplaceOpen, setMarketplaceOpen] = useState(false);
    const [dataSources, setDataSources] = useState<DataSource[]>([]);
    const [selectedSource, setSelectedSource] = useState<DataSource | null>(null);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const fetchChatbot = useCallback(() => {
        if (botId) {
            let htmlPages = 0
            let pdfPages = 0
            let imgs = 0
            let otherContent = 0
            chatbotClient.getChatbot(botId)
                .then(bot => {
                    bot.files = bot.files?.map((f: FileEntity) => {
                        
                        if (f.name.toLowerCase().endsWith(".txt")) {
                            htmlPages++;
                        } else if (f.name.toLowerCase().endsWith(".botpdf")) {
                            pdfPages++;
                        } else if (f.name.toLowerCase().endsWith(".botimg")) {
                            imgs++;
                        } else {
                            otherContent++;
                        }
                         
                        f.name = cleanLinks(f.name)
                        return f
                    })
                    setBot(bot)
                    
                    setHtmlPageCount(htmlPages)
                    setPdfPageCount(pdfPages)
                    setImgsCount(imgs)
                    setOtherContentCount(otherContent)
                    
                    if (!fetchBotInterval.current) {
                        if (botIsTraining(bot)) {
                            fetchBotInterval.current = setInterval(() => fetchChatbot(), 2000)
                        }
                    } else {
                        if (!botIsTraining(bot)) {
                            clearInterval(fetchBotInterval.current || undefined)
                            fetchBotInterval.current = null
                        }
                    }
                })
                .catch(() => { navigate('/chatBots') })
        }
    }, [botId, chatbotClient, navigate])

    useEffect(() => {
        fetchChatbot()
    }, [fetchChatbot])
    
    
    useEffect(() => {
        if (botId) {
            chatbotClient.getChatbotContext(botId)
                .then((botCtx: ChatbotContextResponse) => {
                    if (botCtx.hasCis) {
                        setCisInfos(botCtx.cisInfos)
                        setCisInterviews(botCtx.interviews)
                    }
                    if (botCtx.hasRag) {
                        setRagInfos(botCtx.ragInfos)
                    }
                    if (botCtx.hasRtRag) {
                        setRtRagInfos(botCtx.rtragInfos)
                    }
                    if (botCtx.hasEvents) {
                        setEventInfos(botCtx.eventInfos)
                    }
                    if (botCtx.hasUserInput) {
                        setUserInputInfos(botCtx.usrInfos)
                        setUserUploads(botCtx.uploads)
                    }

                })
        }
        return () => {
            if (fetchBotInterval.current) {
                clearInterval(fetchBotInterval.current || undefined)
            }
        }
        // eslint-disable-next-line
    }, [])
    
    // cisInfos.num_documents, cisInfos.total_bytes, cisInfos.avg_size_bytes, cisInfos.total_tokens, cisInfos.avg_num_tokens
    const getContextStats = (num_documents: number, total_bytes: number, avg_size_bytes: number, total_tokens: number, avg_num_tokens: number) => {
        return (
            <Grid item xs={12} >
                <Grid container sx={{ paddingBottom: "1em", overflow: 'auto' }} spacing={1}>
                    <Grid item xs={2}>
                        <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px"}}>
                            <Grid item>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", color: "gray" }}>
                                    {num_documents.toLocaleString()}
                                </Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "10px", color: "gray" }}>
                                    {"Chunks"}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px"}}>
                            <Grid item>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", color: "gray" }}>
                                    {(Math.round(total_bytes / 1000 * 100) / 100).toLocaleString()}
                                </Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "10px", color: "gray" }}>
                                    {"Overal size [kB]"}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px"}}>
                            <Grid item>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", color: "gray" }}>
                                    {(Math.round((avg_size_bytes / 1000) * 100) / 100).toLocaleString()}
                                </Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "10px", color: "gray" }}>
                                    {"⌀ size [kB]"}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px"}}>
                            <Grid item>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", color: "gray" }}>
                                    {total_tokens.toLocaleString()}
                                </Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "10px", color: "gray" }}>
                                    {"Token"}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px"}}>
                            <Grid item>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", color: "gray" }}>
                                    {(Math.round((avg_num_tokens) * 100) / 100).toLocaleString()}
                                </Typography>
                                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "10px", color: "gray" }}>
                                    {"⌀ token per chunk"}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
        )
    }

    // TODO: Load data sources from backend when API is ready
    useEffect(() => {
        if (botId) {
            // Placeholder: Mock data sources for testing
            setDataSources([
                {
                    id: '1',
                    type: DataSourceType.REALTIME_RAG,
                    name: 'Website RAG',
                    description: 'Test website data source',
                    status: 'active',
                    configuration: {
                        url: 'https://example.com',
                        maxDepth: 2
                    },
                    lastUpdated: new Date()
                }
            ]);
        }
    }, [botId]);

    const handleSaveDataSource = async (type: DataSourceType, configuration: Record<string, any>) => {
        // TODO: Implement API call to create data source
        // POST /api/bot/{botId}/datasources
        
        // Placeholder: Create new source locally
        const newSource: DataSource = {
            id: Math.random().toString(36).substr(2, 9),
            type,
            name: DATA_SOURCE_TYPES[type].name,
            description: '',
            status: 'inactive',
            configuration,
            lastUpdated: new Date()
        };
        setDataSources(prev => [...prev, newSource]);
    };

    const handleUpdateSource = async (configuration: Record<string, any>) => {
        if (!selectedSource) return;
        
        // TODO: Implement API call to update data source
        // PUT /api/bot/{botId}/datasources/{sourceId}
        
        // Placeholder: Update source locally
        setDataSources(prev => prev.map(s => 
            s.id === selectedSource.id 
                ? { ...s, configuration, status: 'inactive', lastUpdated: new Date() }
                : s
        ));
        setSelectedSource(null);
    };

    const handleConfigureSource = (source: DataSource) => {
        setSelectedSource(source);
        setSettingsModalOpen(true);
    };

    const handleDeleteSource = async (source: DataSource) => {
        // TODO: Implement API call to delete data source
        // DELETE /api/bot/{botId}/datasources/{sourceId}
        
        // Placeholder: Remove source locally
        setDataSources(prev => prev.filter(s => s.id !== source.id));
    };

    if (!botId) {
        return <Navigate to={'/chatBots'} />
    }

    if (!bot) {
        return <GlobalLoader />
    }


    return (
        <>
            <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={12}>
                    <Headline title={t('chatbot.myBot', { botName: bot.name })} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center" color="error">{bot.description}</Typography>
                </Grid>
                <Grid item lg={8} md={10} xs={12} >
                    <Paper>
                        <Grid container sx={{ padding: "1em", overflow: 'auto' }}>
                            <Grid item xs={12} container spacing={1} alignItems="center">
                                <Grid item xs={6} container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h5" >{t('chatbot.trainingStatus.label')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TrainingStatusIcon status={bot.trainingStatus} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} spacing={2} container justifyContent="flex-end">
                                    <Grid item>
                                        <Tooltip title={t('chatbot.settings')}>
                                            <IconButton onClick={() => setsettingsOpen(true)}>
                                                <Settings />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={"Users"}>
                                            <IconButton onClick={() => setUserSettingsOpen(true)}>
                                                <PiUsersBold />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    {/*<Grid item>
                                        <DisabledToolTip disabled={bot.trainingStatus !== TrainingStatus.TRAINED} tooltip={t('chatbot.botNotTrainedNoTrainingPossible')}>
                                            <Button disabled={bot.trainingStatus !== TrainingStatus.TRAINED} variant="contained" component={RouterLink} to={`/bot/${bot.id}/chat`}>
                                                {t('chatbot.chat')}
                                            </Button>
                                        </DisabledToolTip>
                                    </Grid>*/}
                                    <Grid item>
                                        <DisabledToolTip tooltip={"Statistics"}>
                                            <Button color="secondary" variant="contained" component={RouterLink} to={`/bot/${bot.id}/stats`}>
                                                Statistics
                                            </Button>
                                        </DisabledToolTip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                
                <Grid item lg={8} md={10} xs={12} >
                    <Grid container sx={{ paddingBottom: "1em", overflow: 'auto' }} spacing={1}>

                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                                <Button disabled={bot.trainingStatus !== TrainingStatus.TRAINED} variant="contained" component={RouterLink} to={`/bot/${bot.id}/chat/iframe/undecorated`} target="_blank" rel="noopener noreferrer">
                                    {t('Chat iFrame')}
                                </Button>
                                <QRCodeLink url={`${window.location.origin}/bot/${bot.id}/chat/iframe/undecorated`} showUrl={false} showTitle={false}/>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                                <Button disabled={bot.trainingStatus !== TrainingStatus.TRAINED} variant="contained" component={RouterLink} to={`/bot/${bot.id}/chat/undecorated`} target="_blank" rel="noopener noreferrer">
                                    {t('Chat Mobile')}
                                </Button>
                                <QRCodeLink url={`${window.location.origin}/bot/${bot.id}/chat/undecorated`} showUrl={false} showTitle={false}/>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                                <Button disabled={bot.trainingStatus !== TrainingStatus.TRAINED} variant="contained" component={RouterLink} to={`/bot/${bot.id}/chat`} target="_blank" rel="noopener noreferrer">
                                    {t('Chat')}
                                </Button>
                                <QRCodeLink url={`${window.location.origin}/bot/${bot.id}/chat`} showUrl={false} showTitle={false}/>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Grid>
                            
                <Grid item lg={8} md={10} xs={12} >
                    <Grid container sx={{ paddingBottom: "1em", overflow: 'auto' }} spacing={1}>
                        <Grid item xs={3}>
                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                <Grid item>
                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                        <BsFileText fontSize={"inherit"}/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                        {htmlPageCount}
                                    </Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                <Grid item>
                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                        <BsFilePdf fontSize={"inherit"}/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                        {pdfPageCount}
                                    </Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                <Grid item>
                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                        <BsFileImage fontSize={"inherit"}/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                        {imgsCount}
                                    </Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper sx={{ display: "flex", alignItems: "center", padding: "10px"}}>
                                <Grid item>
                                    <Typography sx={{ fontSize: "50px", color: "gray" }}>
                                        <BsFileEarmark fontSize={"inherit"}/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ display: "flex", alignItems: "center", fontSize: "30px", color: "gray", marginLeft: "20px" }}>
                                        {otherContentCount}
                                    </Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item lg={8} md={10} xs={12} >
                    <Paper>
                        <Grid container sx={{ padding: "1em", overflow: 'auto' }}>
                            <Grid item xs={12} container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h5">{t('chatbot.dataSources.title')}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <DisabledToolTip disabled={botIsTraining(bot)} tooltip={t('chatbot.botTraining')}>
                                        <Button color="error" disabled={botIsTraining(bot)} variant="contained" onClick={() => setTrainBotModalOpen(true)}>
                                            {t('chatbot.trainNow')}
                                        </Button>
                                    </DisabledToolTip>
                                </Grid>
                                <Grid item xs={2} container justifyContent="flex-end">
                                    <Fab color="primary" onClick={() => setMarketplaceOpen(true)}>
                                        <Add />
                                    </Fab>
                                </Grid>

                                <Grid item xs={12}>
                                    <DataSourceGrid
                                        sources={dataSources}
                                        onConfigureSource={handleConfigureSource}
                                        onDeleteSource={handleDeleteSource}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                
                <Grid item lg={8} md={10} xs={12} >
                    <Paper>
                        <Grid container sx={{ padding: "1em", overflow: 'auto' }}>
                            <Grid item xs={12} container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h5" >{bot.files?.length ?? 0} {t('chatbot.uploadedDocuments')}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"5%"} />
                                                <TableCell width={"50%"}>
                                                    {t('chatbot.document')}
                                                </TableCell>
                                                <TableCell width={"25%"}>
                                                    {t('chatbot.topic')}
                                                </TableCell>
                                                <TableCell width={"15%"}>
                                                    {t('chatbot.uploadedDate')}
                                                </TableCell>
                                                <TableCell width={"10%"} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bot.files?.map((f: FileEntity) => {
                                                return <FileRow
                                                    file={f}
                                                    onDeleted={() => fetchChatbot()}
                                                    onUpdate={() => fetchChatbot()}
                                                />
                                            })}
                                            {(bot.files?.length ?? 0) === 0 && <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography variant="body1" align="center"><i>{t('chatbot.noFilesUploaded')}</i></Typography>
                                                </TableCell>
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* rt-RAG */}
                    {rtragInfos &&
                        <>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Realtime-RAG (" + (bot.settings.web_rag_city || "" )+ ")"} size='section' />
                            </Grid>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                Zwischenspeicher der Echtzeit-RAG Informationen. 
                            </Grid>
                            {getContextStats(rtragInfos.num_documents, rtragInfos.total_bytes, rtragInfos.avg_size_bytes,
                                rtragInfos.total_tokens, rtragInfos.avg_num_tokens)}
                        </>
                    }

                    {/* RAG */}
                    {ragInfos &&
                        <>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"RAG (" + + ragInfos.lastModified + ")"} size='section' />
                            </Grid>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                Speicher der vektorisierten Informationen.
                            </Grid>
                            {getContextStats(ragInfos.num_documents, ragInfos.total_bytes, ragInfos.avg_size_bytes, 
                                ragInfos.total_tokens, ragInfos.avg_num_tokens)}
                        </>
                    }

                    {/* Events */}
                    {eventInfos &&
                        <>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"Events (" + eventInfos.lastModified + ")"} size='section' />
                            </Grid>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                Events aus dem Event-System
                            </Grid>
                            {getContextStats(eventInfos.num_documents, eventInfos.total_bytes, eventInfos.avg_size_bytes,
                                eventInfos.total_tokens, eventInfos.avg_num_tokens)}
                        </>
                    }

                    {/* CIS Interviews */}
                    {cisInterviews.length > 0 && cisInfos && 
                        <>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"CIS (" + cisInfos.lastModified + ")"} size='section' />
                            </Grid>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                Ergebnisse der Interviews aus dem CIS-Modul. Diese stehen dem Bot zur Abfrage zur Verfügung. 
                            </Grid>
                            {getContextStats(cisInfos.num_documents, cisInfos.total_bytes, cisInfos.avg_size_bytes, cisInfos.total_tokens, cisInfos.avg_num_tokens)}
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={cisInterviews.length + " CIS-Inverviews:"} size='section' />
                            </Grid>
                            {cisInterviews.map((interview: FileInfo) => {
                                return (
                                    <Paper sx={{ display: "flex", alignItems: "center", padding: "10px", marginBottom: "8px"}}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} container spacing={1} alignItems="center">
                                                <Grid item xs={4}>
                                                    {interview.filename}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {interview.lastModified}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {(interview.size / 1000) + "kB"}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )

                            })}
                        </>
                    }
                    
                    {/* UserInput */}
                    {userUploads.length > 0 && userInputInfos &&
                        <>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                <Headline title={"User Uploads" + userUploads.length + " Uploads (" + userInputInfos.lastModified + ")"} size='section' />
                            </Grid>
                            <Grid item xs={12} justifyContent='right' sx={{ }}>
                                Dateien, die die Anwender dem Bot zur Verfügung gestellt haben.
                            </Grid>
                            {getContextStats(userInputInfos.num_documents, userInputInfos.total_bytes, 
                                userInputInfos.avg_size_bytes, userInputInfos.total_tokens, userInputInfos.avg_num_tokens)}
                            {userUploads.map((file: FileInfo) => {
                                return (
                                    <Paper sx={{ display: "flex", alignItems: "center", padding: "10px", marginBottom: "8px"}}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} container spacing={1} alignItems="center">
                                                <Grid item xs={4}>
                                                    {file.filename}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {file.lastModified}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {(file.size / 1000) + "kB"}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )

                            })}
                        </>
                    }

                    <Grid item xs={12} spacing={2} container justifyContent="flex-end">
                        <Grid item>
                            <Tooltip title={"Reset Cache"}>
                                <Button color="secondary" variant="contained"
                                        onClick={() => chatbotClient.resetCache(botId)}
                                >
                                    Reset Cache
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
            <UploadFileModal
                open={addFileModalOpen}
                onClose={() => {
                    setAddFileModalOpen(false)
                    fetchChatbot()
                }}
                bot_id={bot.id}
                subfolder={'docs'}
            />
            
            <AddWebsiteModal
                open={addWebsiteModalOpen}
                onClose={() => {
                    setAddWebsiteModalOpen(false)
                    // fetchChatbot()
                }}
                chatbot={bot} 
            />
            
            <TrainChatbotModal
                open={trainBotModalOpen}
                onClose={() => {
                    setTrainBotModalOpen(false)
                    fetchChatbot()
                }}
                chatbot={bot} 
            />
             
            <SettingsModal
                open={settingsOpen}
                onClose={() => setsettingsOpen(false)}
                onBotChange={setBot}
                chatbot={bot} 
            />
             
            <UserSettingsModal
                open={userSettingsOpen}
                onClose={() => setUserSettingsOpen(false)}
                onBotChange={setBot}
                chatbot={bot} 
            />
            
            <DataSourceMarketplaceModal
                open={marketplaceOpen}
                onClose={() => {
                    setMarketplaceOpen(false);
                }}
                onSave={handleSaveDataSource}
                botId={bot.id}
            />

            {selectedSource && (
                <DataSourceSettingsModal
                    open={settingsModalOpen}
                    onClose={() => {
                        setSettingsModalOpen(false);
                        setSelectedSource(null);
                    }}
                    onSave={handleUpdateSource}
                    source={selectedSource}
                    botId={bot.id}
                />
            )}
            
        </>
    );
}

export default MyChatBot;