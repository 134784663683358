import ApiClient, {ClientOptions} from "./ApiClient";
import {createContext, useContext} from "react";
import {useNotificationContext} from "../shared/contexts/NotificationContext";
import {
    Chatbot, ChatbotContextResponse,
    ChatbotPagination,
    ChatbotSettings, ChatbotUser, ChatUsageQuery, ChatUsageResponse, ColorExtractionResponse,
    FeedbackContent, FileToVecstoreResponse,
    TrainingSettings, TranslationRequest
} from "../model/Chatbot";
import {FileEntity} from "../model/FileEntity";
import {ChatRequest} from "../model/ChatResponse";
import {ScrapeRequest} from "../model/ScrapeRequest";

export default class ChatbotApiClient extends ApiClient {
    public getAllChatbots = async (page = 0, pageSize = 20, options?: ClientOptions): Promise<ChatbotPagination> => {
        return this.get(`/chatbot/all?page=${page}&pageSize=${pageSize}`, options);
    }

    public createChatbot = async (name: string, description?: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.post('/chatbot', {body: {name, description}, ...options});
    }

    public getChatbot = async (chatbotId: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.get(`/chatbot/${chatbotId}`, options);
    }

    public getChatbotContext = async (chatbotId: string, options?: ClientOptions): Promise<ChatbotContextResponse> => {
        return this.get(`/context/${chatbotId}`, options);
    }

    public deleteChatbot = async (chatbotId: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.delete(`/chatbot/${chatbotId}`, options);
    }

    public getChatbotUsageStats = async (chatbotId: string, chatUsageQuery: ChatUsageQuery, options?: ClientOptions): Promise<ChatUsageResponse> => {
        return this.post(`/chatbot/${chatbotId}/stats/usage`, { body: chatUsageQuery, ...options });
    }

    public trainChatbot = async (chatbotId: string, trainingSettings: TrainingSettings, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/train`, {body: trainingSettings, ...options});
    }

    public addUserKnowledgeToBot = async (chatbotId: string, options?: ClientOptions): Promise<String> => {
        return this.post(`/chatbot/${chatbotId}/addUserKnowledge`, {body: chatbotId, ...options});
    }

    public chatWithChatbot = async (chatbotId: string, chatRequest: ChatRequest,
                                    onEvent: (event: string, event_type: string) => void,
                                    onClose: (lastChunkReceived: string) => void,
                                    file?: File, // Add the optional file parameter
                                    options?: ClientOptions
    ): Promise<void> => {
        return this.postStream(`/chatbot/${chatbotId}/chat`, onEvent, onClose, file, {body: chatRequest, ...options});
    }

    public setChatbotTrainingStatus = async (chatbotId: string, trainingStatus: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/training_status`, {body: {trainingStatus}, ...options})
    }

    public setChatbotAvailability = async (chatbotId: string, publicAvailable: boolean, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/availability`, {body: {publicAvailable}, ...options})
    }

    public setChatbotSettings = async (chatbotId: string, settings: ChatbotSettings, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/settings`, {body: {settings}, ...options})
    }

    public giveFeedback = async (chatbotId: string, feedbackContent: FeedbackContent, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/feedback`, {body: {feedbackContent}, ...options})
    }

    public createFileForChatbot = async (chatbotId: string, file: Pick<FileEntity, "name" | "subfolder" | "size" | "md5Hash">, options?: ClientOptions): Promise<FileEntity> => {
        return this.post(`/chatbot/${chatbotId}/files`, {body: file, ...options});
    }

    public fileToFaiss = async (file: File, numberExtension: boolean, options?: ClientOptions): Promise<FileToVecstoreResponse> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("numberExtension", numberExtension + "");
        return this.post(`/file/file_to_faiss`, {body: formData, ...options, preventBodyStringify: true}, "<none>");
    }

    public translate = async (translationRequest: TranslationRequest, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/commonservice/translate`, {body: translationRequest, ...options});
    }

    public scrapePage = async (scrapeRequest: ScrapeRequest): Promise<string> => {
        return this.post(`/scraper`, {body: scrapeRequest});
    }

    public subsribeToScrapeStatus = async (scraperID: string,
                                           onEvent: (event: string, event_type: string) => void,
                                           onClose: (lastChunkReceived: string) => void, options?: ClientOptions): Promise<void> => {
        return this.postStream(`/scraper/task-progress/${scraperID}`, onEvent, onClose, undefined, options);
    }

    public grantUserAccess = async (chatbotId: string, email: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.post(`/chatbot/${chatbotId}/allowedUsers`, {body: {email}, ...options})
    }

    public revokeUserAccess = async (chatbotId: string, email: string, options?: ClientOptions): Promise<Chatbot> => {
        return this.delete(`/chatbot/${chatbotId}/allowedUsers`, {body: {email}, ...options})
    }

    public getChatbotAccessUsers = async (chatbotId: string, options?: ClientOptions): Promise<ChatbotUser[]> => {
        return this.get(`/chatbot/${chatbotId}/users`, options)
    }
    
    public updateChatbotAccessUsers = async (chatbotId: string, chatbotUsers: ChatbotUser[], options?: ClientOptions): Promise<string> => {
        return this.post(`/chatbot/${chatbotId}/users`, {body: {chatbotUsers}, ...options})
    }

    public extractColors = async (url: string, threshold: number): Promise<ColorExtractionResponse> => {
        return this.get(`/colors/extract?url=${encodeURIComponent(url)}&threshold=${threshold}`)
    }

    public resetCache = async (chatbotId: string): Promise<string> => {
        return this.delete(`/chatbot/${chatbotId}/clear-cache`)
    }
}


export const ChatbotApiClientContext = createContext<ChatbotApiClient>(new ChatbotApiClient())

export const useChatbotApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(ChatbotApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}